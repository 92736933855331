export default [
    {
        path: '/employee/personal/salary',
        name: 'employee-personal-salary',
        component: () => import('@/views/employeePanel/personal/Salary'),
        meta: {
            action:'salary',
            resource:'Employee Personal',
            pageTitle: 'Employee',
            breadcrumb: [
                {
                    text: 'Salary',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/personal/loan',
        name: 'employee-personal-loan',
        component: () => import('@/views/employeePanel/personal/Loan'),
        meta: {
            action:'loan',
            resource:'Employee Personal',
            pageTitle: 'Employee',
            breadcrumb: [
                {
                    text: 'Loans',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/personal/fund',
        name: 'employee-personal-fund',
        component: () => import('@/views/employeePanel/personal/Fund'),
        meta: {
            action:'provident fund',
            resource:'Employee Personal',
            pageTitle: 'Employee',
            breadcrumb: [
                {
                    text: 'Funds',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/personal/attendance',
        name: 'employee-personal-attendance',
        component: () => import('@/views/employeePanel/personal/Attendance'),
        meta: {
            action:'attendance',
            resource:'Employee Personal',
            pageTitle: 'Employee',
            breadcrumb: [
                {
                    text: 'Attendance',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/personal/leave/request',
        name: 'employee-personal-leave-request',
        component: () => import('@/views/employeePanel/personal/LeaveRequest'),
        meta: {
            action:'leave request',
            resource:'Employee Personal',
            pageTitle: 'Employee',
            breadcrumb: [
                {
                    text: 'Leave Request',
                    active: true,
                },
            ],
        },
    },

]
