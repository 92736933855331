import apiCall from "@/libs/axios";

export default {
    state: {
        public_sub_menus:[],
        only_sub_menus:[],
    },
    getters: {
        public_sub_menus: state => {
            return state.public_sub_menus;
        },
        only_sub_menus: state =>{
            return state.only_sub_menus;
        },
        single_sub_menus:state=>slug=>{
            return state.public_sub_menus.find(item => item.slug === slug);
        },
    },
    mutations: {
        SET_ALL_SUB_MENU_WITH_DATA(state, public_sub_menus) {
            state.public_sub_menus=public_sub_menus;
        },
        SET_ALL_SUB_MENU(state, only_sub_menus) {
            state.only_sub_menus=only_sub_menus;
        },
        UPDATE_SIMPLE_PUBLIC_MENU_DATA(state,[sub_menu_id,data]){
            let index = state.public_sub_menus.findIndex(item=>item.id==sub_menu_id);
            if (index>=0) state.public_sub_menus[index].public_data_simple=data;
        },
        STORE_MULTIPLE_PUBLIC_MENU_DATA(state,[sub_menu_id,data]){
            let index = state.public_sub_menus.findIndex(item=>item.id==sub_menu_id);
            if (index>=0) state.public_sub_menus[index].public_data_multiple.push(data);
        },
        STATUS_CHANGE_MULTIPLE_PUBLIC_SUB_MENU_DATA(state,[sub_menu_id,index]){
            let sub_index = state.public_sub_menus.findIndex(item=>item.id==sub_menu_id);
            if (sub_index>=0) {
                if(state.public_sub_menus[sub_index].public_data_multiple[index].is_active==1){
                    state.public_sub_menus[sub_index].public_data_multiple[index].is_active=0;
                }else{
                    state.public_sub_menus[sub_index].public_data_multiple[index].is_active=1;
                }
            }
        },
        REMOVE_MULTIPLE_PUBLIC_MENU_DATA(state,[sub_menu_id,data_index]){
            let index = state.public_sub_menus.findIndex(item=>item.id==sub_menu_id);
            if (index>=0) {
                state.public_sub_menus[index].public_data_multiple.splice(data_index,1);
            }
        }
    },
    actions: {
        async GET_ALL_SUB_MENU_WITH_DATA(context){
            await apiCall.get('/get/all/public/sub/menus')
                .then(response => {
                    context.commit('SET_ALL_SUB_MENU_WITH_DATA',response.data );
                })
        },
        async GET_ALL_SUB_MENUS(context){
            await apiCall.get('/get/only/public/sub/menus')
                .then(response => {
                    context.commit('SET_ALL_SUB_MENU',response.data );
                })
        }
    },
}
