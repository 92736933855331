export default [

    {
        path: '/class/day',
        name: 'class-day',
        component: () => import('@/views/routine/ClassDay.vue'),
        meta: {
            action:'read',
            resource:'Class Day',
            pageTitle: 'Class Day',
            breadcrumb: [
                {
                    text: 'Class Day',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/period',
        name: 'class-period',
        component: () => import('@/views/routine/ClassPeriod.vue'),
        meta: {
            action:'read',
            resource:'Time Slot',
            pageTitle: 'Time Slot',
            breadcrumb: [
                {
                    text: 'Time Slot',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/routine/add',
        name: 'class-routine-add',
        component: () => import('@/views/routine/ClassRoutineAdd.vue'),
        meta: {
            action:'create',
            resource:'Class Routine',
            pageTitle: 'Add Class Routine',
            breadcrumb: [
                {
                    text: 'Class Routine',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/routine',
        name: 'class-routine',
        component: () => import('@/views/routine/ClassRoutine.vue'),
        meta: {
            action:'read',
            resource:'Class Routine',
            pageTitle: 'Class Routine',
            breadcrumb: [
                {
                    text: 'Class Routine',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/exam/routine',
        name: 'exam-routine',
        component: () => import('@/views/routine/ExamRoutine.vue'),
        meta: {
            action:'read',
            resource:'Exam Routine',
            pageTitle: 'Exam Routine',
            breadcrumb: [
                {
                    text: 'Exam Routine',
                    active: true,
                },
            ],
        },
    },

]
