import apiCall from "@/libs/axios";

export default {
    state: {
        class_routines:[],
        active_class_routines:[],
    },
    getters: {
        class_routines: state => {
            return state.class_routines;
        },
        active_class_routines: state => {
            return state.active_class_routines;
        },
    },
    mutations: {
        SET_ALL_CLASS_ROUTINE(state, class_routines) {
            state.class_routines=class_routines;
        },
        SET_ACTIVE_CLASS_ROUTINE(state, active_class_routines) {
            state.active_class_routines=active_class_routines;
        },
    },
    actions: {
        async GET_ALL_CLASS_ROUTINE(context){
            await apiCall.get('/class/routines')
                .then(res => {
                    context.commit('SET_ALL_CLASS_ROUTINE',res.data );
                })
        },
        async GET_ACTIVE_CLASS_ROUTINE(context){
            await apiCall.get('/active/class/routines')
                .then(res => {
                    context.commit('SET_ACTIVE_CLASS_ROUTINE',res.data );
                })
        }
    },
}
