import apiCall from "@/libs/axios";

export default {
    state: {
        subjects:[],
        active_subjects:[],
    },
    getters: {
        subjects: state => {
            return state.subjects;
        },
        active_subjects: state => {
            return state.active_subjects;
        },
        subjectByID: state =>id=> {
            let s=state.subjects.find(item=>parseInt(item.id)===parseInt(id));
            return s?s.name:'';
        },
    },
    mutations: {
        SET_ALL_SUBJECT(state, subjects) {
            state.subjects=subjects;
        },
        SET_ACTIVE_SUBJECT(state, active_subjects) {
            state.active_subjects=active_subjects;
        },
        REMOVE_SUBJECT(state,index){
            state.subjects.splice(index,1);
        },
        STATUS_CHANGE_SUBJECT(state,id){
            let index = state.subjects.findIndex(item=>item.id===parseInt(id));
            if (index>=0) state.subjects[index].is_active==0?state.subjects[index].is_active=1:state.subjects[index].is_active=0;
        }
    },
    actions: {
        async GET_ALL_SUBJECT(context){
            await apiCall.get('/get/all/subject')
                .then(res => {
                    context.commit('SET_ALL_SUBJECT',res.data );
                })
        },
        async GET_ACTIVE_SUBJECT(context){
            await apiCall.get('/get/active/subject')
                .then(res => {
                    context.commit('SET_ACTIVE_SUBJECT',res.data );
                })
        }
    },
}
