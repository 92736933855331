import apiCall from "@/libs/axios";

export default {
    state: {
        roles:[],
        permissions:[],
    },
    getters: {
        roles: state => {
            return state.roles;
        },
        permissions: state => {
            return state.permissions;
        },
        employeeRoles(state){
            return state.roles.filter(item=> ![4,5].includes(item.id));
        },
        roleByID: state=>id=>{
            let s=state.roles.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
    },
    mutations: {
        SET_ALL_PERMISSION(state, permissions) {
            state.permissions=permissions;
        },
        SET_ALL_ROLE(state, roles) {
            state.roles=roles;
        },
        ADD_NEW_ROLE(state, scale) {
            state.roles.push(scale);
        },
        STATUS_CHANGE_ROLE(state, index) {
            if(state.roles[index].is_active) state.roles[index].is_active=0;
            else state.roles[index].is_active=1;
        },
        REMOVE_ROLE(state, index) {
            state.roles.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_ROLE(context){
           await apiCall.get('/get/all/roles')
                .then(res => {
                    context.commit('SET_ALL_ROLE',res.data );
                })
        },
        async GET_ALL_MENU_PERMISSION(context){
           await apiCall.get('/menu/permissions')
                .then(res => {
                    context.commit('SET_ALL_PERMISSION',res.data );
                })
        }
    },
}
