export default [
    {
        path: '/employee/task/course/material',
        name: 'employee-task-course-material',
        component: () => import('@/views/employeePanel/task/CourseMaterial'),
        meta: {
            action:'course material',
            resource:'Employee Task',
            pageTitle: 'Classes',
            breadcrumb: [
                {
                    text: 'Course Materials',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/task/course/material:classes/:version/:shift/:wing/:student_group/:section/:academic_year/:subject',
        name: 'employee-task-course-material',
        component: () => import('@/views/employeePanel/task/CourseMaterialView'),
        props:true,
        meta: {
            action:'course material',
            resource:'Employee Task',
            pageTitle: 'Classes',
            breadcrumb: [
                {
                    text: 'Course Materials',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/task/homework',
        name: 'employee-task-homework',
        component: () => import('@/views/employeePanel/task/HomeWork'),
        meta: {
            action:'home work',
            resource:'Employee Task',
            pageTitle: 'Classes',
            breadcrumb: [
                {
                    text: 'Homeworks',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/task/homework:classes/:version/:shift/:wing/:student_group/:section/:academic_year/:subject',
        name: 'employee-task-homework',
        component: () => import('@/views/employeePanel/task/HomeworkView'),
        props:true,
        meta: {
            action:'home work',
            resource:'Employee Task',
            pageTitle: 'Classes',
            breadcrumb: [
                {
                    text: 'Homeworks',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/task/mark/upload',
        name: 'employee-task-mark-upload',
        component: () => import('@/views/employeePanel/task/MarkUpload'),
        meta: {
            action:'mark upload',
            resource:'Employee Task',
            pageTitle: 'Mark Upload',
            breadcrumb: [
                {
                    text: 'Task',
                },
                {
                    text: 'Mark Upload',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/task/mark/upload:classes/:version/:shift/:wing/:student_group/:section/:academic_year/:subject',
        name: 'employee-task-mark-upload',
        component: () => import('@/views/employeePanel/task/StudentMarkUpload'),
        props:true,
        meta: {
            action:'mark upload',
            resource:'Employee Task',
            pageTitle: 'Mark Upload',
            breadcrumb: [
                {
                    text: 'Task',
                },
                {
                    text: 'Mark Upload',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/task/attendance',
        name: 'employee-task-attendance',
        component: () => import('@/views/employeePanel/task/attendance/Attendance'),
        meta: {
            action:'attendance',
            resource:'Employee Task',
            pageTitle: 'Attendance',
            breadcrumb: [
                {
                    text: 'Task',
                },
                {
                    text: 'Attendance',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/task/take/attendance:classes/:version/:shift/:wing/:student_group/:section/:academic_year/:subject',
        name: 'employee-task-attendance',
        component: () => import('@/views/employeePanel/task/attendance/TakeAttendance'),
        props:true,
        meta: {
            action:'attendance',
            resource:'Employee Task',
            pageTitle: 'Attendance',
            breadcrumb: [
                {
                    text: 'Task',
                },
                {
                    text: 'Take',
                },
                {
                    text: 'Attendance',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/task/view/attendance:classes/:version/:shift/:wing/:student_group/:section/:academic_year/:subject',
        name: 'employee-task-attendance',
        component: () => import('@/views/employeePanel/task/attendance/ViewAttendance'),
        props:true,
        meta: {
            action:'attendance',
            resource:'Employee Task',
            pageTitle: 'Attendance',
            breadcrumb: [
                {
                    text: 'Task',
                },
                {
                    text: 'View',
                },
                {
                    text: 'Attendance',
                    active: true,
                },
            ],
        },
    },

]
