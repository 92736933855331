import apiCall from "@/libs/axios";

export default {
    state: {
        class_days:[],
        active_class_days:[],
    },
    getters: {
        class_days: state => {
            return state.class_days;
        },
        active_class_days: state => {
            return state.active_class_days;
        },
    },
    mutations: {
        SET_ALL_CLASS_DAY(state, class_days) {
            state.class_days=class_days;
        },
        SET_ACTIVE_CLASS_DAY(state, active_class_days) {
            state.active_class_days=active_class_days;
        },
    },
    actions: {
        async GET_ALL_CLASS_DAY(context){
            await apiCall.get('/get/all/class/day')
                .then(res => {
                    context.commit('SET_ALL_CLASS_DAY',res.data );
                })
        },
        async GET_ACTIVE_CLASS_DAY(context){
            await apiCall.get('/get/active/class/day')
                .then(res => {
                    context.commit('SET_ACTIVE_CLASS_DAY',res.data );
                })
        }
    },
}
