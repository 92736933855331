import apiCall from "@/libs/axios";

export default {
    state: {
        student_payments:[],
    },
    getters: {
        student_payments: state => {
            return state.student_payments;
        },
    },
    mutations: {
        SET_ALL_STUDENT_PAYMENT(state, student_payments) {
            state.student_payments=student_payments;
        },
        REMOVE_STUDENT_PAYMENT(state,index){
            state.student_payments.splice(index,1);
        }
    },
    actions: {
        async GET_ALL_STUDENT_PAYMENT(context){
            await apiCall.get('/get/student/payments')
                .then(res => {
                    context.commit('SET_ALL_STUDENT_PAYMENT',res.data );
                })
        },
    },
}
