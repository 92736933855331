import apiCall from "@/libs/axios";

export default {
    state: {
        contact_information:{},
    },
    getters: {
        contact_information: state => {
            return state.contact_information;
        },
    },
    mutations: {
        SET_ALL_CONTACT_INFORMATION(state, contact_information) {
            state.contact_information=contact_information;
        },
    },
    actions: {
        async GET_ALL_CONTACT_INFORMATION(context){
            await apiCall.get('/get/contact/information')
                .then(response => {
                    context.commit('SET_ALL_CONTACT_INFORMATION',response.data );
                })
        }
    },
}
