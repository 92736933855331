import apiCall from "@/libs/axios";

export default {
    state: {
        syllabuses:[],
        approved_syllabuses:[],
    },
    getters: {
        syllabuses: state => {
            return state.syllabuses;
        },
        approved_syllabuses: state => {
            return state.approved_syllabuses;
        },
    },
    mutations: {
        SET_ALL_SYLLABUSES_LIST(state, syllabuses) {
            state.syllabuses=syllabuses;
        },
        SET_APPROVED_SYLLABUSES_LIST(state, approved_syllabuses) {
            state.approved_syllabuses=approved_syllabuses;
        },
    },
    actions: {
        async GET_ALL_SYLLABUSES_LIST(context){
            await apiCall.get('/syllabuses')
                .then(res => {
                    context.commit('SET_ALL_SYLLABUSES_LIST',res.data );
                })
        },
        async GET_APPROVED_SYLLABUSES_LIST(context){
            await apiCall.get('/approved/syllabus')
                .then(res => {
                    context.commit('SET_APPROVED_SYLLABUSES_LIST',res.data );
                })
        }
    },
}
