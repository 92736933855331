import apiCall from "@/libs/axios";

export default {
    state: {
        employee_loans:[],
    },
    getters: {
        employee_loans: state => {
            return state.employee_loans;
        },
    },
    mutations: {
        SET_ALL_EMPLOYEE_LOAN(state, employee_loans) {
            state.employee_loans=employee_loans;
        },
        ADD_NEW_EMPLOYEE_LOAN(state, department) {
            state.employee_loans.push(department);
        },
        STATUS_CHANGE_EMPLOYEE_LOAN(state, index) {
            if(state.employee_loans[index].is_active) state.employee_loans[index].is_active=0;
            else state.employee_loans[index].is_active=1;
        },
        REMOVE_EMPLOYEE_LOAN(state, index) {
            state.employee_loans.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE_LOAN(context){
           await apiCall.get('/get/all/employee/loan')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_LOAN',res.data );
                })
        }
    },
}
