import apiCall from "@/libs/axios";

export default {
    state: {
        attendance_employees:[],
        attendance_students:[],
    },
    getters: {
        attendance_employees: state => {
            return state.attendance_employees;
        },
        attendance_students: state => {
            return state.attendance_students;
        },
    },
    mutations: {
        SET_ALL_EMPLOYEE_ATTENDANCE(state, attendance_employees) {
            state.attendance_employees=attendance_employees;
        },
        SET_ALL_STUDENT_ATTENDANCE(state, attendance_students) {
            state.attendance_students=attendance_students;
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE_ATTENDANCE(context){
            await apiCall.get('/get/all/employee/attendances')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_ATTENDANCE',res.data );
                })
        },
        async GET_ALL_STUDENT_ATTENDANCE(context){
            await apiCall.get('/get/all/student/attendances')
                .then(res => {
                    context.commit('SET_ALL_STUDENT_ATTENDANCE',res.data );
                })
        },
    },
}
