import apiCall from "@/libs/axios";

export default {
    state: {
        shifts:[],
    },
    getters: {
        shifts: state => {
            return state.shifts;
        },
        shiftByID: state=>id=>{
            let s=state.shifts.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_SHIFT(state, shifts) {
            state.shifts=shifts;
        },
    },
    actions: {
        async GET_ALL_SHIFT(context){
            await apiCall.get('/get/all/shift')
                    .then(res => {
                        context.commit('SET_ALL_SHIFT',res.data );
                    })
        }
    },
}
