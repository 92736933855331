export default [
    {
        path: '/parent/children',
        name: 'parent-child',
        component: () => import('@/views/parentPanel/Student'),
        meta: {
            action:'children',
            resource:'Parent',
            pageTitle: 'Parent',
            breadcrumb: [
                {
                    text: 'Children',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/parent/subjects',
        name: 'parent-subject',
        component: () => import('@/views/parentPanel/Subject'),
        meta: {
            action:'subject',
            resource:'Parent',
            pageTitle: 'Parent',
            breadcrumb: [
                {
                    text: 'Subjects',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/parent/homeworks',
        name: 'parent-homework',
        component: () => import('@/views/parentPanel/HomeWork'),
        meta: {
            action:'homework',
            resource:'Parent',
            pageTitle: 'Parent',
            breadcrumb: [
                {
                    text: 'Homeworks',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/parent/course/materials',
        name: 'parent-course-material',
        component: () => import('@/views/parentPanel/CourseMaterial'),
        meta: {
            action:'course material',
            resource:'Parent',
            pageTitle: 'Parent',
            breadcrumb: [
                {
                    text: 'Course Materials',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/parent/child/attendances',
        name: 'parent-child-attendance',
        component: () => import('@/views/parentPanel/Attendance'),
        meta: {
            action:'attendance',
            resource:'Parent',
            pageTitle: 'Parent',
            breadcrumb: [
                {
                    text: 'Attendance',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/parent/child/results',
        name: 'parent-child-results',
        component: () => import('@/views/parentPanel/Result'),
        meta: {
            action:'result',
            resource:'Parent',
            pageTitle: 'Parent',
            breadcrumb: [
                {
                    text: 'Results',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/parent/child/payments',
        name: 'parent-child-payment',
        component: () => import('@/views/parentPanel/Payment'),
        meta: {
            action:'payment',
            resource:'Parent',
            pageTitle: 'Parent',
            breadcrumb: [
                {
                    text: 'Payments',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/parent/child/payment/history',
        name: 'parent-child-payment-history',
        component: () => import('@/views/parentPanel/PaymentHistory'),
        meta: {
            action:'payment history',
            resource:'Parent',
            pageTitle: 'Parent',
            breadcrumb: [
                {
                    text: 'Payment History',
                    active: true,
                },
            ],
        },
    }

]
