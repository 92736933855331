import apiCall from "@/libs/axios";

export default {
    state: {
        results:[],
    },
    getters: {
        results: state => {
            return state.results;
        },
    },
    mutations: {
        SET_ALL_RESULT(state, results) {
            state.results=results;
        },
    },
    actions: {
        async GET_ALL_RESULT(context){
            await apiCall.get('/get/all/result')
                .then(res => {
                    context.commit('SET_ALL_RESULT',res.data );
                })
        },
    },
}
