import apiCall from "@/libs/axios";

export default {
    state: {
        forth_subjects:[],
    },
    getters: {
        forth_subjects: state => {
            return state.forth_subjects;
        },
    },
    mutations: {
        SET_ALL_FORTH_SUBJECT(state, forth_subjects) {
            state.forth_subjects=forth_subjects;
        },
    },
    actions: {
        async GET_ALL_FORTH_SUBJECT(context){
            await apiCall.get('/get/all/student/assigned/fourth/subject')
                .then(res => {
                    context.commit('SET_ALL_FORTH_SUBJECT',res.data );
                })
        },
    },
}
