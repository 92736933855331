import apiCall from "@/libs/axios";

export default {
    state: {
        students:[],
        active_students:[],
        student_disciplinary_records:[],
    },
    getters: {
        students: state => {
            return state.students;
        },
        active_students: state => {
            return state.active_students;
        },
        studentIdByID: state=>id=>{
            let s=state.students.find(item=>item.id===parseInt(id));
            return s?s.student_id:'';
        },
        cadetIdByID: state=>id=>{
            let student_info = state.students.find(item=>item.id==parseInt(id));
            return student_info?student_info.cadet_no:'NF';
        },
        studentNameByID: state=>id=>{
            let s=state.students.find(item=>item.id===parseInt(id));
            return s?s.userable?s.userable.name:'':'';
        },
        student_disciplinary_records: state => {
            return state.student_disciplinary_records;
        },
    },
    mutations: {
        SET_ALL_STUDENT(state, students) {
            state.students=students;
        },
        SET_ALL_ACTIVE_STUDENT(state, active_students) {
            state.active_students = active_students;
        },
        STUDENT_STATUS_CHANGE(state,id){
            let index = state.students.findIndex(item=>item.id==id);
            if (index>=0) state.students[index].is_active==0?state.students[index].is_active=1:state.students[index].is_active=0;
        },
        SET_ALL_STUDENT_DISCIPLINARY_RECORD(state, student_disciplinary_records) {
            state.student_disciplinary_records=student_disciplinary_records;
        },
    },
    actions: {
        async GET_ALL_STUDENT(context){
            await apiCall.get('/get/all/students')
                .then(res => {
                    context.commit('SET_ALL_STUDENT',res.data );
                })
        },
        async GET_ALL_ACTIVE_STUDENT(context){
            await apiCall.get('/get/all/active/students')
                .then(res => {
                    context.commit('SET_ALL_ACTIVE_STUDENT', res.data );
                })
        },
        async GET_ALL_STUDENT_DISCIPLINARY_RECORD(context){
            await apiCall.get('/student/disciplinary/records')
                .then(res => {
                    context.commit('SET_ALL_STUDENT_DISCIPLINARY_RECORD',res.data );
                })
        },
    },
}
