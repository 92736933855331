import apiCall from "@/libs/axios";

export default {
    state: {
        departments:[],
    },
    getters: {
        departments: state => {
            return state.departments;
        },
        departmentByID: state=>id=>{
            let s=state.departments.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_DEPARTMENT(state, departments) {
            state.departments=departments;
        },
        ADD_NEW_DEPARTMENT(state, department) {
            state.departments.push(department);
        },
        STATUS_CHANGE_DEPARTMENT(state, index) {
            if(state.departments[index].is_active) state.departments[index].is_active=0;
            else state.departments[index].is_active=1;
        },
        REMOVE_DEPARTMENT(state, index) {
            state.departments.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_DEPARTMENT(context){
           await apiCall.get('/get/all/department')
                .then(res => {
                    context.commit('SET_ALL_DEPARTMENT',res.data );
                })
        }
    },
}
