export default [
    {
        path: '/payment/type',
        name: 'payment-type',
        component: () => import('@/views/payment/PaymentType'),
        meta: {
            action:'read',
            resource:'Payment Type',
            pageTitle: 'Payment Type',
            breadcrumb: [
                {
                    text: 'Payment type',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/take/student/payment',
        name: 'take-student-payment',
        component: () => import('@/views/payment/TakeStudentPayment'),
        meta: {
            action:'take',
            resource:'Payment',
            pageTitle: 'Payments',
            breadcrumb: [
                {
                    text: 'Take Student Payment',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/payment',
        name: 'student-payment',
        component: () => import('@/views/payment/StudentPayment'),
        meta: {
            action:'read',
            resource:'Payment',
            pageTitle: 'Payments',
            breadcrumb: [
                {
                    text: 'Student Payment',
                    active: true,
                },
            ],
        },
    },

]
