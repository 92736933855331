export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
          action:'read',
          resource:'Dashboard',
          pageTitle: 'Dashboard',
          breadcrumb: [
            {
              text: 'Dashboard',
              active: true,
            },
          ],
        },
      },
      {
        path: '/institute',
        name: 'institute',
        component: () => import('@/views/Institute.vue'),
        meta: {
          action:'read',
          resource:'Dashboard',
          pageTitle: 'Institute',
          breadcrumb: [
            {
              text: 'Institute',
              active: true,
            },
          ],
        },
      },
      {
        path: '/conference/room',
        name: 'conference.room',
        component: () => import('@/views/ConferenceRoom.vue'),
        meta: {
          action:'read',
          resource:'Dashboard',
          pageTitle: 'Conference Room',
          breadcrumb: [
            {
              text: 'Conference Room',
              active: true,
            },
          ],
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/Profile'),
        meta: {
          action:'full',
          resource:'Auth',
        },
      },
]