import apiCall from "@/libs/axios";

export default {
    state: {
        conference_rooms: [],
    },
    getters: {
        conference_rooms: state => {
            return state.conference_rooms;
        },
    },
    mutations: {
        SET_ALL_CONFERENCE_ROOM(state, conference_rooms) {
            state.conference_rooms=conference_rooms;
        },
    },
    actions: {
        async GET_ALL_CONFERENCE_ROOM(context){
            context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/conference/room/information')
                .then(res => {
                    context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_CONFERENCE_ROOM',res.data );
                })
        },
    },
}
