import apiCall from "@/libs/axios";

export default {
    state: {
        student_waiver_types:[],
        active_student_waiver_types:[],
    },
    getters: {
        student_waiver_types: state => {
            return state.student_waiver_types;
        },
        active_student_waiver_types: state => {
            return state.student_waiver_types.filter(item=>item.is_active==1);
        },
        waiverTypeByID: state=>id=>{
            let s=state.student_waiver_types.find(item=>item.id===id);
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_STUDENT_WAIVER_TYPE(state, student_waiver_types) {
            state.student_waiver_types=student_waiver_types;
        },
        SET_ACTIVE_STUDENT_WAIVER_TYPE(state, active_student_waiver_types) {
            state.active_student_waiver_types=active_student_waiver_types;
        },
        ADD_NEW_STUDENT_WAIVER_TYPE(state, data) {
            state.student_waiver_types.push(data);
        },
        STATUS_CHANGE_STUDENT_WAIVER_TYPE(state, index) {
            if(state.student_waiver_types[index].is_active ==1){
                state.student_waiver_types[index].is_active =0
            }else{
                state.student_waiver_types[index].is_active =1
            }
        },
        APPROVAL_STATUS_CHANGE_STUDENT_WAIVER_TYPE(state, [index,status]) {
            state.student_waiver_types[index].approval_status =status;
        },
        REMOVE_STUDENT_WAIVER_TYPE(state, index) {
            state.student_waiver_types.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_STUDENT_WAIVER_TYPE(context){
            await apiCall.get('/get/student/waiver/types')
                .then(res => {
                    context.commit('SET_ALL_STUDENT_WAIVER_TYPE',res.data );
                })
        },
        async GET_ACTIVE_STUDENT_WAIVER_TYPE(context){
            await apiCall.get('/get/active/student/waiver/types')
                .then(res => {
                    context.commit('SET_ACTIVE_STUDENT_WAIVER_TYPE',res.data );
                })
        }
    },
}
