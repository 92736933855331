<template>
  <b-card-code>
    <div class="custom-search d-flex">
      <b-button variant="outline-primary" @click="showModal">
        {{ $t('Add New') }}
      </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('Search') }}</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="videos"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: index -->
        <span v-else-if="props.column.field === 'description'">
          <span v-if="props.row.description">
            <template v-for="(vv,key) in jsonDecode(props.row.description)">
             <!-- Youtube Embed -->
            <LazyYoutube :src="vv" :key="key"/>
              <!-- Vimeo Embed -->
<!--            <LazyVimeo v-else :src="vv" :key="key"/>-->
            </template>
          </span>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'is_active'">
          <template>
            <b-form-checkbox :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id,props.row.originalIndex)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
          </template>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
                <b-button @click="selectedRow(props.row)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-login
                    variant="outline-primary" >
                  <feather-icon icon="Edit2Icon" class="mr-50"/>
                </b-button>
              <b-button variant="outline-danger" @click="remove(props.row.id,props.row.originalIndex)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="xl"
             hide-footer
             :title="model_mode==='add'?$t('Add New'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_new_video">
          <b-row>
            <b-col md="6">
              <b-card-text>
                <span>{{ $t('Category/Title') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Title')"
                    rules="required"
                >
                  <b-form-input
                      v-model="title"
                      name="title"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Write title here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" v-for="(video,key) in up_videos" :key="key">
              <b-row >
                <b-col md="6">
                  <b-card-text>
                    <span>{{ $t('Video URL') + (key+1) }} </span>
                  </b-card-text>
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Video URL')+ (key+1)"
                        rules="required"
                    >
                      <b-form-input v-model="video.video"
                                    name="videos[]"
                                    :state="errors.length > 0 ? false:null"
                                    :placeholder="$t('Write video url')">

                      </b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" v-if="video.video">
                  <LazyYoutube :src="video.video"/>
                </b-col>
              <!--   remove button             -->
                <b-col md="2">
                  <b-button class="mt-5" v-show="key || ( !key && up_videos.length > 1)"
                      variant="danger"
                      type="button" @click="removeVideoField(key)"
                  >
                    <feather-icon icon="TrashIcon" class="mr-50"/>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <b-col>
                <b-button @click="addMore"
                    variant="secondary"
                    type="button"
                >
                  Add more
                </b-button>
              </b-col>
            </b-col>
            <!-- submit button -->
            <b-col>
              <b-button style="float: right"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,BCardText,
  BModal, BRow, BCol,BFormCheckbox,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import { LazyYoutube } from "vue-lazytube";
export default {
  name:'VideoGallery',
  components: {
    BCardCode,BFormCheckbox,
    VueGoodTable,BCardText,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,
    BRow, BCol,
    ValidationProvider,
    ValidationObserver,LazyYoutube,//LazyVimeo,
  },
  directives: {
    Ripple,
  },
  props:{
    sub_menu:{
      type:Object,
      required:true,
    }
  },
  data() {
    return {
      video_type:'youtube',
      title: '',
      up_videos:[
        {video:''}
      ],
      image: null,
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Category',
          field: 'title',
        },
        {
          label: 'Video',
          field: 'description',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
    }
  },
  methods:{
    jsonDecode(data){
      try{
        return JSON.parse(data);
      }catch (e){
        return [];
      }
    },
    selectedRow(row){
      this.selected_row=row;
      this.title=row.title;
      this.up_videos=[];
      this.jsonDecode(row.description).forEach(item=>{
        this.up_videos.push({
          video:item,
        });
      });
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_new_video);
          data.append('public_sub_menu_id',this.sub_menu.id);
          data.append('page_design_type_id',this.sub_menu.page_design_type_id);
          if (this.model_mode ==='add'){
            apiCall.post('/menu/multiple/video/store',data).then((response)=>{
              this.hideModal();
              this.$refs.simpleRules.reset();
              this.$store.commit('STORE_MULTIPLE_PUBLIC_MENU_DATA', [this.sub_menu.id, response.data.data]);
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/menu/multiple/video/update${this.selected_row.id}`,data).then((response)=>{
              this.hideModal();
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_SUB_MENU_WITH_DATA');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    async statusChange(id,index){
      await apiCall.put(`/menu/multiple/data/common/status/change${id}`).then((response)=>{
        this.$store.commit('STATUS_CHANGE_MULTIPLE_PUBLIC_SUB_MENU_DATA',[this.sub_menu.id,index]);
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_SUB_MENU_WITH_DATA');
      });
    },
    remove(id,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/menu/multiple/video/remove${id}`).then((response)=>{
            this.$store.commit('REMOVE_MULTIPLE_PUBLIC_MENU_DATA',[this.sub_menu.id,index]);
            this.$toaster.success(response.data.message);
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    showModal() {
      this.selected_row={};
      this.title='';
      this.up_videos=[{video:''}];
      this.model_mode='add';
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    addMore(){
      this.up_videos.push({video:''});
    },
    removeVideoField(key){
      this.up_videos.splice(key,1);
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    videos(){
      return this.sub_menu.public_data_multiple??[];
    },
    //...mapGetters(['versions']),
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>