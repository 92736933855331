import apiCall from "@/libs/axios";

export default {
    state: {
        banks:[],
    },
    getters: {
        banks: state => {
            return state.banks;
        },
        active_banks:state=>{
            return state.banks.filter(item=>item.is_active===1);
        },
        bankById:state=>id=>{
            return state.banks.find(item=>parseInt(item.id)===parseInt(id));
        },
    },
    mutations: {
        SET_ALL_BANK(state, banks) {
            state.banks=banks;
        },
        ADD_NEW_BANK(state,data){
            state.banks.push(data);
        },
        UPDATE_BANK(state,[data,index]){
            state.banks[index].name=data;
        },
        REMOVE_BANK(state,index){
            state.banks.splice(index,1);
        },
        BANK_STATUS_CHANGE(state,index){
            state.banks[index].is_active= !state.banks[index].is_active;
        },
    },
    actions: {
        async GET_ALL_BANK(context){
            await apiCall.get('/get/banks')
                .then(res => {
                    context.commit('SET_ALL_BANK',res.data );
                })
        },
        async GET_ACTIVE_BANK(context){
            await apiCall.get('/active/banks')
                .then(res => {
                    context.commit('SET_ACTIVE_BANK',res.data );
                })
        }
    },
}
