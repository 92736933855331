import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from "@/store/auth";
import version from "@/store/settings/version";
import shift from "@/store/settings/shift";
import studentGroup from "@/store/settings/studentGroup";
import wing from "@/store/settings/wing";
import academicYear from "@/store/settings/academicYear";
import religion from "@/store/settings/religion";
import section from "@/store/settings/section";
import classes from "@/store/settings/classes";
import subject from "@/store/subject";
import classDay from "@/store/routine/classDay"
import classPeriod from "@/store/routine/classPeriod"
import classRoutine from "@/store/routine/classRoutine"
import examType from "@/store/exam/examType";
import examRoutine from "@/store/routine/examRoutine";
import bookList from "@/store/task/bookList";
import syllabus from "@/store/task/syllabus";
import studentFeeOption from "@/store/fee/studentFeeOption";
import studentFeeType from "@/store/fee/studentFeeType";
import assignStudentFee from "@/store/fee/assignStudentFee";
import subscriber from "@/store/public-site/subscriber";
import contactUs from "@/store/public-site/contactUs";
import contactInformation from "@/store/public-site/contactInformation";
import slider from "@/store/public-site/slider";
import homePage from "@/store/public-site/homePage";
import publicMenus from "@/store/public-site/publicMenus"
import publicSubMenus from "@/store/public-site/publicSubMenus";
import gender from "@/store/settings/gender";
import blood_group from "@/store/settings/blood_group";
import student from "@/store/student/student";
import menuIcon from "@/store/public-site/menuIcon";
import pageDesignType from "@/store/public-site/pageDesignType";
import ExpenseType from "@/store/expense/expenseType";
import subExpenseType from "@/store/expense/subExpenseType";
import expense from "@/store/expense/expense";
import paymentType from "@/store/payment/paymentType";
import bank from "@/store/account/bank";
import bankAccount from "@/store/account/bankAccount";
import studentWaiverType from "@/store/studentWaiver/studentWaiverType";
import studentWaiver from "@/store/studentWaiver/studentWaiver";
import studentWaiverForAll from "@/store/studentWaiver/studentWaiverForAll";
import studentPayment from "@/store/payment/studentPayment";
import department from "@/store/employee/department";
import designation from "@/store/employee/designation";
import scale from "@/store/employee/scale";
import designationScale from "@/store/employee/designationScale";
import bonusType from "@/store/employee/bonusType";
import employeeBonus from "@/store/employee/employeeBonus";
import providentFund from "@/store/employee/providentFund";
import employee from "@/store/employee/employee";
import maritalStatus from "@/store/settings/maritalStatus";
import role from "@/store/RoleAndPermission/role";
import employeeLoan from "@/store/employee/employeeLoan";
import salary from "@/store/employee/salary";
import classTeacher from "@/store/task/classTeacher";
import subjectTeacher from "@/store/task/subjectTeacher";
import employeeSubject from "@/store/employeePanel/task/employeeSubject";
import employeeCourseMaterial from "@/store/employeePanel/task/employeeCourseMaterial";
import employeeHomeWork from "@/store/employeePanel/task/employeeHomeWork";
import salaryPersonal from "@/store/employeePanel/personal/salary"
import loan from "@/store/employeePanel/personal/loan"
import fund from "@/store/employeePanel/personal/fund";
import gradeType from "@/store/exam/gradeType";
import markDistribution from "@/store/exam/markDistribution";
import studentMarkUpload from "@/store/employeePanel/task/studentMarkUpload";
import marquee from "@/store/public-site/marquee";
import facility from "@/store/public-site/facility";
import footer from "@/store/public-site/footer";
import studentForthSubject from "@/store/student/studentForthSubject";
import studentGroupSubject from "@/store/student/studentGroupSubject";
import socialLink from "@/store/public-site/socialLink";
import result from "@/store/result/result"
import attendanceStatus from "@/store/attendance/attendanceStatus";
import studentAttendance from "@/store/employeePanel/task/studentAttendance";
import personal_attendance from "@/store/employeePanel/personal/personal_attendance";
import allAttendance from "@/store/attendance/allAttendance";
import leave_request from "@/store/attendance/leave_request";
import notification from "@/store/notification";
import studentParent from "@/store/student/studentParent";
import institute from "@/store/institute";
import conferenceRoom from "@/store/conferenceRoom";
import admissionInstruction from "@/store/admission/admissionInstruction";
import admissionSchedule from "@/store/admission/admissionSchedule";
import admissionFee from "@/store/admission/admissionFee";
import admissionDate from "@/store/admission/admissionDate";
import admissionPayment from "@/store/admission/admissionPayment";
import admissionStudent from "@/store/admission/admissionStudent";
import studentFeeHead from "@/store/fee/studentFeeHead";
import user from "@/store/user/user";

Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    loader:false,
  },
  getters:{
    loader: state=>{ return state.loader;}
  },
  mutations:{
    LOADER_STATUS_CHANGE(state, status){
      state.loader= status;
    }
  },
  modules: {
    app,
    appConfig,institute,
    verticalMenu,leave_request,conferenceRoom,
    auth,gender,blood_group,allAttendance,
    version,bonusType,personal_attendance,
    shift,role,gradeType,markDistribution,
    studentGroup,studentForthSubject, 
    studentGroupSubject,
    wing,studentWaiver,studentWaiverType,
    academicYear,studentWaiverForAll,
    religion,studentPayment,
    section,department,designation,
    classes,scale,designationScale,
    subject,employee,salary,
    classDay,maritalStatus,
    classPeriod,employeeLoan,
    classRoutine,classTeacher,
    examType,subjectTeacher,
    examRoutine,employeeSubject,
    bookList,employeeCourseMaterial,
    syllabus,employeeHomeWork,
    studentFeeOption,salaryPersonal,loan,fund,
    studentFeeType,studentMarkUpload,
    assignStudentFee,marquee,facility,
    subscriber,footer,socialLink,
    contactUs,result,attendanceStatus,
    contactInformation,studentAttendance,
    slider,menuIcon,notification,
    homePage,student,pageDesignType,
    publicMenus,publicSubMenus,bank,bankAccount,
    ExpenseType,subExpenseType,expense,paymentType,
    employeeBonus,providentFund,studentParent,
    admissionInstruction,admissionSchedule,admissionFee,
    admissionDate,admissionPayment,admissionStudent,
    studentFeeHead,
    user
  },
  strict: process.env.DEV,
})
