import apiCall from "@/libs/axios";

export default {
    state: {
        designations:[],
    },
    getters: {
        designations: state => {
            return state.designations;
        },
        designationByID: state=>id=>{
            let s=state.designations.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_DESIGNATION(state, designations) {
            state.designations=designations;
        },
        ADD_NEW_DESIGNATION(state, designation) {
            state.designations.push(designation);
        },
        STATUS_CHANGE_DESIGNATION(state, index) {
            if(state.designations[index].is_active) state.designations[index].is_active=0;
            else state.designations[index].is_active=1;
        },
        REMOVE_DESIGNATION(state, index) {
            state.designations.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_DESIGNATION(context){
           await apiCall.get('/get/all/designation')
                .then(res => {
                    context.commit('SET_ALL_DESIGNATION',res.data );
                })
        }
    },
}
