import apiCall from "@/libs/axios";

export default {
    state: {
        attendance_statuses:[],
    },
    getters: {
        attendance_statuses: state => {
            return state.attendance_statuses;
        },
        attendanceStatusByID: state=>id=>{
            let s=state.attendance_statuses.find(item=>parseInt(item.id)===parseInt(id));
            return s?s.alias:'';
        },
    },
    mutations: {
        SET_ALL_ATTENDANCE_STATUS(state, attendance_statuses) {
            state.attendance_statuses=attendance_statuses;
        },
    },
    actions: {
        async GET_ALL_ATTENDANCE_STATUS(context){
            await apiCall.get('/get/all/attendance/status')
                .then(res => {
                    context.commit('SET_ALL_ATTENDANCE_STATUS',res.data );
                })
        },
    },
}
