import apiCall from "@/libs/axios";

export default {
    state: {
        personal_attendances:[],
        personal_leave_requests:[],
    },
    getters: {
        personal_attendances: state => {
            return state.personal_attendances;
        },
        personal_leave_requests: state => {
            return state.personal_leave_requests;
        },
    },
    mutations: {
        SET_EMPLOYEE_PERSONAL_ATTENDANCE(state, personal_attendances) {
            state.personal_attendances=personal_attendances;
        },
        SET_EMPLOYEE_PERSONAL_LEAVE(state, personal_leave_requests) {
            state.personal_leave_requests=personal_leave_requests;
        },
    },
    actions: {
        async GET_EMPLOYEE_PERSONAL_ATTENDANCE(context){
            await apiCall.get('/employee/personal/attendances')
                .then(res => {
                    context.commit('SET_EMPLOYEE_PERSONAL_ATTENDANCE',res.data );
                })
        },
        async GET_EMPLOYEE_PERSONAL_LEAVE_REQUEST(context){
            await apiCall.get('/employee/personal/leave/request')
                .then(res => {
                    context.commit('SET_EMPLOYEE_PERSONAL_LEAVE',res.data );
                })
        },
    }
}
