export default [
    {
        path: '/department',
        name: 'employee-department',
        component: () => import('@/views/employee/Department'),
        meta: {
            action:'read',
            resource:'Department',
            pageTitle: 'Departments',
            breadcrumb: [
                {
                    text: 'Department',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/designation',
        name: 'employee-designation',
        component: () => import('@/views/employee/Designation'),
        meta: {
            action:'read',
            resource:'Designation',
            pageTitle: 'Designations',
            breadcrumb: [
                {
                    text: 'Employee',
                    active: false,
                },
                {
                    text: 'Designations',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/scale',
        name: 'employee-scale',
        component: () => import('@/views/employee/Scale'),
        meta: {
            action:'read',
            resource:'Scale',
            pageTitle: 'Scales',
            breadcrumb: [
                {
                    text: 'Employee',
                    active: false,
                },
                {
                    text: 'Scales',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/designation/scale',
        name: 'employee-designation-scale',
        component: () => import('@/views/employee/DesignationScale'),
        meta: {
            action:'read',
            resource:'Designation & Scale',
            pageTitle: 'Designation & Scales',
            breadcrumb: [
                {
                    text: 'Employee',
                    active: false,
                },
                {
                    text: 'Designation & Scales',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee',
        name: 'employee',
        component: () => import('@/views/employee/Employee'),
        meta: {
            action:'read',
            resource:'Employee',
            pageTitle: 'Employee',
            breadcrumb: [
                {
                    text: 'Employees',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/employee/bonus/type',
        name: 'employee-bonus-type',
        component: () => import('@/views/employee/BonusType'),
        meta: {
            action:'read',
            resource:'Bonus Type',
            pageTitle: 'Bonus Types',
            breadcrumb: [
                {
                    text: 'Employee',
                    active: false,
                },
                {
                    text: 'Bonus Types',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/bonus',
        name: 'employee-bonus',
        component: () => import('@/views/employee/EmployeeBonus'),
        meta: {
            action:'read',
            resource:'Employee Bonus',
            pageTitle: 'Bonus',
            breadcrumb: [
                {
                    text: 'Employee',
                    active: false,
                },
                {
                    text: 'Bonus',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/salary',
        name: 'employee-salary',
        component: () => import('@/views/employee/EmployeeSalary'),
        meta: {
            action:'read',
            resource:'Salary',
            pageTitle: 'Salary',
            breadcrumb: [
                {
                    text: 'Employee',
                    active: false,
                },
                {
                    text: 'Salary',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/provident/fund',
        name: 'employee-provident-fund',
        component: () => import('@/views/employee/ProvidentFund'),
        meta: {
            action:'read',
            resource:'Provident Fund',
            pageTitle: 'Provident Fund',
            breadcrumb: [
                {
                    text: 'Employee',
                    active: false,
                },
                {
                    text: 'Provident Funds',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/loan',
        name: 'employee-loan',
        component: () => import('@/views/employee/EmployeeLoan'),
        meta: {
            action:'read',
            resource:'Employee Loan',
            pageTitle: 'Loan',
            breadcrumb: [
                {
                    text: 'Employee',
                    active: false,
                },
                {
                    text: 'Loan',
                    active: true,
                },
            ],
        },
    },
]
