export default [
    {
        path: '/tution/and/messing',
        name: 'rpt-tution-and-messing',
        component: () => import('@/views/reports/TutionNMessingFee'),
        meta: {
            action:'read',
            resource:'Tution & Messing',
            pageTitle: 'Tution & Messing',
            breadcrumb: [
                {
                    text: 'Tution & Messing',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/canteen/bill/info',
        name: 'rpt-canteen-bill',
        component: () => import('@/views/reports/CanteenBill'),
        meta: {
            action:'read',
            resource:'Canteen',
            pageTitle: 'Canteen Bill Reports',
            breadcrumb: [
                {
                    text: 'Canteen bill',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/cadet/report',
        name: 'rpt-cadet-info',
        component: () => import('@/views/reports/CadetInfo'),
        meta: {
            action:'read',
            resource:'Cadet Report',
            pageTitle: 'Cadet Report',
            breadcrumb: [
                {
                    text: 'Cadet Report',
                    active: true,
                },
            ],
        },
    }
]
