import apiCall from "@/libs/axios";

export default {
    state: {
        wings:[],
    },
    getters: {
        wings: state => {
            return state.wings;
        },
        wingByID: state=>id=>{
            let s = state.wings.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        getDesiredWings: state=>ids=>{
            var resultArray = new Array();
            var wing_array = state.wings; 
           
            for (var i = 0; i < wing_array.length; i++) {
              
                if(ids.toString().includes(wing_array[i].id)){
                    resultArray.push(wing_array[i].name);
                }
            }
           
            return resultArray.toString();
        }
    },
    mutations: {
        SET_ALL_WING(state, wings) {
            state.wings = wings;
        },
    },
    actions: {
        async GET_ALL_WING(context){
            await apiCall.get('/get/all/wing')
                    .then(res => {
                        context.commit('SET_ALL_WING',res.data );
                    })
        }
    },
}
