export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
  {
    action: 'full',
    subject: 'Auth',
  },
    /*
  {
    action: 'read',
    subject: 'Main Panel',
  },
  /!*subjects*!/
  {
    action: 'read',
    subject: 'Dashboard',
  },
  {
    action: 'read',
    subject: 'Subject',
  },
  {
    action: 'create',
    subject: 'Subject',
  },
  {
    action: 'update',
    subject: 'Subject',
  },
  {
    action: 'remove',
    subject: 'Subject',
  },
  {
    action: 'status change',
    subject: 'Subject',
  },
  /!*exam management*!/
  {
    action: 'read',
    subject: 'Grade Manage',
  },
  {
    action: 'create',
    subject: 'Grade Manage',
  },
  {
    action: 'update',
    subject: 'Grade Manage',
  },
  {
    action: 'remove',
    subject: 'Grade Manage',
  },
  {
    action: 'read',
    subject: 'Exam Type',
  },
  {
    action: 'create',
    subject: 'Exam Type',
  },
  {
    action: 'update',
    subject: 'Exam Type',
  },
  {
    action: 'remove',
    subject: 'Exam Type',
  },
  {
    action: 'status change',
    subject: 'Exam Type',
  },
  {
    action: 'read',
    subject: 'Mark Distribution',
  },
  {
    action: 'update',
    subject: 'Mark Distribution',
  },
  {
    action: 'create',
    subject: 'Mark Distribution',
  },

  /!*result processing*!/
  {
    action: 'generate',
    subject: 'Result Processing',
  },
  {
    action: 'publish',
    subject: 'Result Processing',
  },
  {
    action: 'result by subject',
    subject: 'Result Processing',
  },
  {
    action: 'result by part',
    subject: 'Result Processing',
  },
  {
    action: 'all result',
    subject: 'Result Processing',
  },
  /!*routines*!/
  {
    action: 'read',
    subject: 'Class Day',
  },
  {
    action: 'status change',
    subject: 'Class Day',
  },
  {
    action: 'read',
    subject: 'Time Slot',
  },
  {
    action: 'create',
    subject: 'Time Slot',
  },
  {
    action: 'update',
    subject: 'Time Slot',
  },
  {
    action: 'remove',
    subject: 'Time Slot',
  },
  {
    action: 'status change',
    subject: 'Time Slot',
  },
  {
    action: 'create',
    subject: 'Class Routine',
  },
  {
    action: 'update',
    subject: 'Class Routine',
  },
  {
    action: 'remove',
    subject: 'Class Routine',
  },
  {
    action: 'status change',
    subject: 'Class Routine',
  },
  {
    action: 'read',
    subject: 'Class Routine',
  },
  {
    action: 'read',
    subject: 'Exam Routine',
  },
  {
    action: 'create',
    subject: 'Exam Routine',
  },
  {
    action: 'update',
    subject: 'Exam Routine',
  },
  {
    action: 'remove',
    subject: 'Exam Routine',
  },
  {
    action: 'status change',
    subject: 'Exam Routine',
  },
    /!*student*!/
  {
    action: 'create',
    subject: 'Student',
  },
  {
    action: 'read',
    subject: 'Student',
  },
  {
    action: 'update',
    subject: 'Student',
  },
  {
    action: 'promotion',
    subject: 'Student',
  },
  {
    action: 'tc',
    subject: 'Student',
  },
  {
    action: 'read',
    subject: 'Parent',
  },
  {
    action: 'read',
    subject: '4th subject',
  },
  {
    action: 'assign',
    subject: '4th subject',
  },
  {
    action: 'remove',
    subject: '4th subject',
  },
  /!*fees*!/
  {
    action: 'read',
    subject: 'Payment Option',
  },
  {
    action: 'create',
    subject: 'Payment Option',
  },
  {
    action: 'update',
    subject: 'Payment Option',
  },
  {
    action: 'remove',
    subject: 'Payment Option',
  },
  {
    action: 'status change',
    subject: 'Payment Option',
  },
  {
    action: 'read',
    subject: 'Fee Type',
  },
  {
    action: 'create',
    subject: 'Fee Type',
  },
  {
    action: 'update',
    subject: 'Fee Type',
  },
  {
    action: 'remove',
    subject: 'Fee Type',
  },
  {
    action: 'status change',
    subject: 'Fee Type',
  },
  {
    action: 'read',
    subject: 'Assigned Fee',
  },
  {
    action: 'create',
    subject: 'Assigned Fee',
  },
  {
    action: 'update',
    subject: 'Assigned Fee',
  },
  {
    action: 'remove',
    subject: 'Assigned Fee',
  },
  {
    action: 'status change',
    subject: 'Assigned Fee',
  },
    /!*waivers*!/
  {
    action: 'read',
    subject: 'Waiver Type',
  },
  {
    action: 'create',
    subject: 'Waiver Type',
  },
  {
    action: 'update',
    subject: 'Waiver Type',
  },
  {
    action: 'remove',
    subject: 'Waiver Type',
  },
  {
    action: 'status change',
    subject: 'Waiver Type',
  },
  {
    action: 'read',
    subject: 'Waiver',
  },
  {
    action: 'create',
    subject: 'Waiver',
  },
  {
    action: 'update',
    subject: 'Waiver',
  },
  {
    action: 'remove',
    subject: 'Waiver',
  },
  {
    action: 'read',
    subject: 'Waiver For All',
  },
  {
    action: 'create',
    subject: 'Waiver For All',
  },
  {
    action: 'update',
    subject: 'Waiver For All',
  },
  {
    action: 'remove',
    subject: 'Waiver For All',
  },
    /!*payments*!/
  {
    action: 'read',
    subject: 'Payment Type',
  },
  {
    action: 'status change',
    subject: 'Payment Type',
  },
  {
    action: 'take',
    subject: 'Payment',
  },
  {
    action: 'remove',
    subject: 'Payment',
  },
  {
    action: 'read',
    subject: 'Payment',
  },
    /!*bank and accounts*!/
  {
    action: 'read',
    subject: 'Bank',
  },
  {
    action: 'create',
    subject: 'Bank',
  },
  {
    action: 'update',
    subject: 'Bank',
  },
  {
    action: 'remove',
    subject: 'Bank',
  },
  {
    action: 'status change',
    subject: 'Bank',
  },
  {
    action: 'read',
    subject: 'Account',
  },
  {
    action: 'status change',
    subject: 'Account',
  },
  {
    action: 'create',
    subject: 'Account',
  },
  {
    action: 'remove',
    subject: 'Account',
  },
  {
    action: 'update',
    subject: 'Account',
  },
    /!*Expenditures*!/
  {
    action: 'create',
    subject: 'Expense Type',
  },
  {
    action: 'read',
    subject: 'Expense Type',
  },
  {
    action: 'update',
    subject: 'Expense Type',
  },
  {
    action: 'remove',
    subject: 'Expense Type',
  },
  {
    action: 'status change',
    subject: 'Expense Type',
  },
  {
    action: 'create',
    subject: 'Sub Expense',
  },
  {
    action: 'read',
    subject: 'Sub Expense',
  },
  {
    action: 'update',
    subject: 'Sub Expense',
  },
  {
    action: 'remove',
    subject: 'Sub Expense',
  },
  {
    action: 'status change',
    subject: 'Sub Expense',
  },
  {
    action: 'read',
    subject: 'Expense',
  },
  {
    action: 'create',
    subject: 'Expense',
  },
  {
    action: 'update',
    subject: 'Expense',
  },
  {
    action: 'remove',
    subject: 'Expense',
  },
    /!*Employee*!/
  {
    action: 'create',
    subject: 'Department',
  },
  {
    action: 'read',
    subject: 'Department',
  },
  {
    action: 'update',
    subject: 'Department',
  },
  {
    action: 'remove',
    subject: 'Department',
  },
  {
    action: 'status change',
    subject: 'Department',
  },
  {
    action: 'create',
    subject: 'Designation',
  },
  {
    action: 'read',
    subject: 'Designation',
  },
  {
    action: 'update',
    subject: 'Designation',
  },
  {
    action: 'remove',
    subject: 'Designation',
  },
  {
    action: 'status change',
    subject: 'Designation',
  },
  {
    action: 'create',
    subject: 'Scale',
  },
  {
    action: 'read',
    subject: 'Scale',
  },
  {
    action: 'update',
    subject: 'Scale',
  },
  {
    action: 'remove',
    subject: 'Scale',
  },
  {
    action: 'status change',
    subject: 'Scale',
  },
  {
    action: 'create',
    subject: 'Designation & Scale',
  },
  {
    action: 'read',
    subject: 'Designation & Scale',
  },
  {
    action: 'update',
    subject: 'Designation & Scale',
  },
  {
    action: 'remove',
    subject: 'Designation & Scale',
  },
  {
    action: 'status change',
    subject: 'Designation & Scale',
  },
  {
    action: 'create',
    subject: 'Employee',
  },
  {
    action: 'read',
    subject: 'Employee',
  },
  {
    action: 'update',
    subject: 'Employee',
  },
  {
    action: 'remove',
    subject: 'Employee',
  },
  {
    action: 'status change',
    subject: 'Employee',
  },
  {
    action: 'create',
    subject: 'Bonus Type',
  },
  {
    action: 'read',
    subject: 'Bonus Type',
  },
  {
    action: 'update',
    subject: 'Bonus Type',
  },
  {
    action: 'remove',
    subject: 'Bonus Type',
  },
  {
    action: 'status change',
    subject: 'Bonus Type',
  },
  {
    action: 'read',
    subject: 'Employee Bonus',
  },
  {
    action: 'create',
    subject: 'Employee Bonus',
  },
  {
    action: 'update',
    subject: 'Employee Bonus',
  },
  {
    action: 'remove',
    subject: 'Employee Bonus',
  },
  {
    action: 'status change',
    subject: 'Employee Bonus',
  },
  {
    action: 'read',
    subject: 'Salary',
  },
  {
    action: 'generate',
    subject: 'Salary',
  },
  /!*provident fund and loan*!/
  {
    action: 'read',
    subject: 'Provident Fund',
  },
  {
    action: 'read',
    subject: 'Employee Loan',
  },
  {
    action: 'create',
    subject: 'Employee Loan',
  },
    /!*Task*!/
  {
    action: 'read',
    subject: 'Book List',
  },
  {
    action: 'create',
    subject: 'Book List',
  },
  {
    action: 'update',
    subject: 'Book List',
  },
  {
    action: 'remove',
    subject: 'Book List',
  },
  {
    action: 'read',
    subject: 'Syllabus',
  },
  {
    action: 'create',
    subject: 'Syllabus',
  },
  {
    action: 'update',
    subject: 'Syllabus',
  },
  {
    action: 'remove',
    subject: 'Syllabus',
  },
  {
    action: 'read',
    subject: 'Class Teacher',
  },
  {
    action: 'create',
    subject: 'Class Teacher',
  },
  {
    action: 'remove',
    subject: 'Class Teacher',
  },
  {
    action: 'status change',
    subject: 'Class Teacher',
  },
  {
    action: 'read',
    subject: 'Subject Teacher',
  },
  {
    action: 'create',
    subject: 'Subject Teacher',
  },
  {
    action: 'remove',
    subject: 'Subject Teacher',
  },
  {
    action: 'status change',
    subject: 'Subject Teacher',
  },
  /!*attendance*!/
  {
    action: 'read',
    subject: 'Employee Attendance',
  },
  {
    action: 'read',
    subject: 'Employee Leave Request',
  },
  {
    action: 'read',
    subject: 'Student Attendance',
  },

    /!*
    * settings
    * *!/
  {
    action: 'read',
    subject: 'Version',
  },
  {
    action: 'create',
    subject: 'Version',
  },
  {
    action: 'update',
    subject: 'Version',
  },
  {
    action: 'remove',
    subject: 'Version',
  },
  {
    action: 'status change',
    subject: 'Version',
  },

  {
    action: 'read',
    subject: 'Shift',
  },
  {
    action: 'create',
    subject: 'Shift',
  },
  {
    action: 'update',
    subject: 'Shift',
  },
  {
    action: 'remove',
    subject: 'Shift',
  },
  {
    action: 'status change',
    subject: 'Shift',
  },

  {
    action: 'read',
    subject: 'Student Group',
  },
  {
    action: 'create',
    subject: 'Student Group',
  },
  {
    action: 'update',
    subject: 'Student Group',
  },
  {
    action: 'remove',
    subject: 'Student Group',
  },
  {
    action: 'status change',
    subject: 'Student Group',
  },

  {
    action: 'read',
    subject: 'Wing',
  },
  {
    action: 'create',
    subject: 'Wing',
  },
  {
    action: 'update',
    subject: 'Wing',
  },
  {
    action: 'remove',
    subject: 'Wing',
  },
  {
    action: 'status change',
    subject: 'Wing',
  },

  {
    action: 'read',
    subject: 'Section',
  },
  {
    action: 'create',
    subject: 'Section',
  },
  {
    action: 'update',
    subject: 'Section',
  },
  {
    action: 'remove',
    subject: 'Section',
  },
  {
    action: 'status change',
    subject: 'Section',
  },
  /!*ac year*!/
  {
    action: 'read',
    subject: 'Academic Year',
  },
  {
    action: 'create',
    subject: 'Academic Year',
  },
  {
    action: 'update',
    subject: 'Academic Year',
  },
  {
    action: 'remove',
    subject: 'Academic Year',
  },
  {
    action: 'status change',
    subject: 'Academic Year',
  },
  /!*religions*!/
  {
    action: 'read',
    subject: 'Religion',
  },
  {
    action: 'create',
    subject: 'Religion',
  },
  {
    action: 'update',
    subject: 'Religion',
  },
  {
    action: 'remove',
    subject: 'Religion',
  },
  {
    action: 'status change',
    subject: 'Religion',
  },

  /!*classes*!/
  {
    action: 'read',
    subject: 'Classes',
  },
  {
    action: 'create',
    subject: 'Classes',
  },
  {
    action: 'update',
    subject: 'Classes',
  },
  {
    action: 'remove',
    subject: 'Classes',
  },
  {
    action: 'status change',
    subject: 'Classes',
  },
    /!*map section*!/
  {
    action: 'read',
    subject: 'Map Section',
  },
  {
    action: 'create',
    subject: 'Map Section',
  },
  {
    action: 'update',
    subject: 'Map Section',
  },
  /!*Wings Vs. Section*!/
  {
    action: 'read',
    subject: 'Wings Vs. Section',
  },
  {
    action: 'create',
    subject: 'Wings Vs. Section',
  },
  {
    action: 'update',
    subject: 'Wings Vs. Section',
  },
  /!*sms*!/
  {
    action: 'create',
    subject: 'SMS',
  },
  {
    action: 'read',
    subject: 'SMS',
  },

  /!*public site*!/
  {
    action: 'read',
    subject: 'Pubic Site',
  },

    /!*
    * employee panel
    * *!/
  {
    action: 'employee',
    subject: 'Employee',
  },
  {
    action: 'home work',
    subject: 'Employee Task',
  },
  {
    action: 'course material',
    subject: 'Employee Task',
  },
  {
    action: 'attendance',
    subject: 'Employee Task',
  },
  {
    action: 'mark upload',
    subject: 'Employee Task',
  },
  {
    action: 'employee',
    subject: 'Employee Personal',
  },
  {
    action: 'salary',
    subject: 'Employee Personal',
  },
  {
    action: 'provident fund',
    subject: 'Employee Personal',
  },
  {
    action: 'loan',
    subject: 'Employee Personal',
  },
  {
    action: 'attendance',
    subject: 'Employee Personal',
  },
  {
    action: 'leave request',
    subject: 'Employee Personal',
  },
    /!*
    * student panel
    * *!/
  {
    action: 'student',
    subject: 'Student',
  },
  {
    action: 'subject',
    subject: 'Student',
  },
  {
    action: 'class routine',
    subject: 'Student',
  },
  {
    action: 'exam routine',
    subject: 'Student',
  },
  {
    action: 'homework',
    subject: 'Student',
  },
  {
    action: 'course material',
    subject: 'Student',
  },
  {
    action: 'attendance',
    subject: 'Student',
  },
  {
    action: 'leave request',
    subject: 'Student',
  },
  {
    action: 'result',
    subject: 'Student',
  },
  {
    action: 'payment',
    subject: 'Student',
  },
  {
    action: 'payment history',
    subject: 'Student',
  },

    /!*
    * parent panel
    * *!/
  {
    action: 'parent',
    subject: 'Parent',
  },
  {
    action: 'children',
    subject: 'Parent',
  },
  {
    action: 'subject',
    subject: 'Parent',
  },
/!*  {
    action: 'class routine',
    subject: 'Parent',
  },
  {
    action: 'exam routine',
    subject: 'Parent',
  },*!/
  {
    action: 'homework',
    subject: 'Parent',
  },
  {
    action: 'course material',
    subject: 'Parent',
  },
  {
    action: 'attendance',
    subject: 'Parent',
  },
  {
    action: 'result',
    subject: 'Parent',
  },
  {
    action: 'payment',
    subject: 'Parent',
  },
  {
    action: 'payment history',
    subject: 'Parent',
  },*/
]

export const _ = undefined
