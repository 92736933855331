export default [
    {
        path: '/employee/global/attendances',
        name: 'employee-global-attendance',
        component: () => import('@/views/attendance/EmployeeAttendance'),
        meta: {
            action: 'read',
            resource: 'Employee Attendance',
            pageTitle: 'Attendance',
            breadcrumb: [
                {
                    text: 'Attendance',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/employee/leave/request',
        name: 'employee-leave-request',
        component: () => import('@/views/attendance/EmployeeLeaveRequest'),
        meta: {
            action: 'read',
            resource: 'Employee Leave Request',
            pageTitle: 'Attendance',
            breadcrumb: [
                {
                    text: 'Leave Request',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/global/attendances',
        name: 'student-global-attendance',
        component: () => import('@/views/attendance/StudentAttendance'),
        meta: {
            action: 'read',
            resource: 'Student Attendance',
            pageTitle: 'Attendance',
            breadcrumb: [
                {
                    text: 'Student Attendance',
                    active: true,
                },
            ],
        },
    },
    

]
