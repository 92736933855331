export default [
    {
        path: '/religion',
        name: 'religion',
        component: () => import('@/views/settings/Religion.vue'),
        meta: {
            action:'read',
            resource:'Religion',
            pageTitle: 'Religion',
            breadcrumb: [
                {
                    text: 'Religion',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/reset/password',
        name: 'reset-password',
        component: () => import('@/views/settings/ResetPassword.vue'),
        meta: {
            action:'read',
            resource:'Reset Password',
            pageTitle: 'Reset Password',
            breadcrumb: [
                {
                    text: 'Reset Password',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/version',
        name: 'version',
        component: () => import('@/views/settings/Version.vue'),
        meta: {
            action:'read',
            resource:'Version',
            pageTitle: 'Versions',
            breadcrumb: [
                {
                    text: 'Versions',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/shift',
        name: 'shift',
        component: () => import('@/views/settings/Shift.vue'),
        meta: {
            action:'read',
            resource:'Shift',
            pageTitle: 'Shifts',
            breadcrumb: [
                {
                    text: 'Shifts',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/group',
        name: 'student-group',
        component: () => import('@/views/settings/StudentGroup.vue'),
        meta: {
            action:'read',
            resource:'Student Group',
            pageTitle: 'Student Groups',
            breadcrumb: [
                {
                    text: 'Student groups',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/wing',
        name: 'wing',
        component: () => import('@/views/settings/Wing.vue'),
        meta: {
            action:'read',
            resource:'Wing',
            pageTitle: 'Wing',
            breadcrumb: [
                {
                    text: 'Wing',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/section',
        name: 'section',
        component: () => import('@/views/settings/Section.vue'),
        meta: {
            action:'read',
            resource:'Section',
            pageTitle: 'Section',
            breadcrumb: [
                {
                    text: 'Sections',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/academic/year',
        name: 'academic-year',
        component: () => import('@/views/settings/AcademicYear.vue'),
        meta: {
            action:'read',
            resource:'Academic Year',
            pageTitle: 'Academic Year',
            breadcrumb: [
                {
                    text: 'Academic Year',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/classes',
        name: 'classes',
        component: () => import('@/views/settings/class/Classes.vue'),
        meta: {
            action:'read',
            resource:'Classes',
            pageTitle: 'Class List',
            breadcrumb: [
                {
                    text: 'Classes',
                },
                {
                    text: 'Details',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/class/add',
        name: 'class-add',
        component: () => import('@/views/settings/class/ClassesAdd.vue'),
        meta: {
            action:'create',
            resource:'Classes',
            pageTitle: 'Add new class',
            breadcrumb: [
                {
                    text: 'Classes',
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/class/update:id',
        name: 'class-update',
        props:true,
        component: () => import('@/views/settings/class/ClassesUpdate.vue'),
        meta: {
            action:'update',
            resource:'Classes',
            pageTitle: 'Classes update',
            breadcrumb: [
                {
                    text: 'Classes',
                },
                {
                    text: 'update',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/wing/section/add',
        name: 'wing-section-add',
        component: () => import('@/views/settings/class/WingSectionAdd.vue'),
        meta: {
            action:'create',
            resource:'Map Section',
            pageTitle: 'Classes Wing & Section add',
            breadcrumb: [
                {
                    text: 'Classes',
                    route: 'classes',
                },
                {
                    text: 'Wing & Section',
                    route: 'classes',
                },
                {
                    text: 'Wing & Section add',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/wing/section',
        name: 'wing-section',
        component: () => import('@/views/settings/class/WingSection.vue'),
        meta: {
            action:'read',
            resource:'Wings Vs. Section',
            pageTitle: 'Classes Wing & section',
            breadcrumb: [
                {
                    text: 'Classes',
                    route: 'classes',
                },
                {
                    text: 'Wing & Section',
                    active: true,
                },
            ],
        },
    },

]
