import apiCall from "@/libs/axios";

export default {
    state: {
        marital_statuses:[],
    },
    getters: {
        marital_statuses: state => {
            return state.marital_statuses;
        },
        maritalStatusById:state=>id=>{
            let s=state.marital_statuses.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_MARITAL_STATUS(state, marital_statuses) {
            state.marital_statuses=marital_statuses;
        },
    },
    actions: {
        async GET_ALL_MARITAL_STATUS(context){
            await apiCall.get('/get/all/marital/status')
                    .then(res => {
                        context.commit('SET_ALL_MARITAL_STATUS',res.data );
                    })
        },
    },
}
