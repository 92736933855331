import apiCall from "@/libs/axios";

export default {
    state: {
        employee_homeworks:[],
    },
    getters: {
        employee_homeworks: state => {
            return state.employee_homeworks;
        },
    },
    mutations: {
        SET_ALL_EMPLOYEE_HOMEWORK(state, employee_homeworks) {
            state.employee_homeworks=employee_homeworks;
        },
        ADD_EMPLOYEE_HOMEWORK(state, work) {
            state.employee_homeworks.push(work);
        },
        REMOVE_EMPLOYEE_HOMEWORK(state, index) {
            state.employee_homeworks.splice(index,1);
        },
        STATUS_CHANGE_EMPLOYEE_HOMEWORK(state, index) {
            if(state.employee_homeworks[index].is_active) state.employee_homeworks[index].is_active=0;
            else state.employee_homeworks[index].is_active=1;
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE_HOMEWORK(context){
            await apiCall.get('/employee/homeworks')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_HOMEWORK',res.data );
                })
        },
    }
}
