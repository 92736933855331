import apiCall from "@/libs/axios";

export default {
    state: {
        student_waivers:[],
        active_student_waivers:[],
    },
    getters: {
        student_waivers: state => {
            return state.student_waivers;
        },
        active_student_waivers: state => {
            return state.active_student_waivers;
        },
        studentWaiverById: state=>id=>{
            return state.student_waivers.find(item=>item.id==id);
        }
    },
    mutations: {
        SET_ALL_STUDENT_WAIVER(state, student_waivers) {
            state.student_waivers=student_waivers;
        },
        SET_ACTIVE_STUDENT_WAIVER(state, active_student_waivers) {
            state.active_student_waivers=active_student_waivers;
        },
        ADD_NEW_STUDENT_WAIVER(state, data) {
            state.student_waivers.push(data);
        },
        STATUS_CHANGE_STUDENT_WAIVER(state, index) {
            if(state.student_waivers[index].is_active ==1){
                state.student_waivers[index].is_active =0
            }else{
                state.student_waivers[index].is_active =1
            }
        },
        APPROVAL_STATUS_CHANGE_STUDENT_WAIVER(state, [index,status]) {
            state.student_waivers[index].approval_status =status;
        },
        REMOVE_STUDENT_WAIVER(state, index) {
            state.student_waivers.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_STUDENT_WAIVER(context){
            await apiCall.get('/get/student/waivers')
                .then(res => {
                    context.commit('SET_ALL_STUDENT_WAIVER', res.data );
                })
        },
        async GET_ACTIVE_STUDENT_WAIVER(context){
            await apiCall.get('/get/active/student/waivers')
                .then(res => {
                    context.commit('SET_ACTIVE_STUDENT_WAIVER',res.data );
                })
        }
    },
}
