import apiCall from "@/libs/axios";

export default {
    state: {
        student_fee_types:[],
        active_student_fee_types:[],
    },
    getters: {
        student_fee_types: state => {
            return state.student_fee_types;
        },
        active_student_fee_types: state => {
            return state.student_fee_types.filter(item=>item.is_active==1);
        },
        studentFeeTypeByID: state=>id=>{
            let s=state.student_fee_types.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_STUDENT_FEE_TYPE(state, student_fee_types) {
            state.student_fee_types=student_fee_types;
        },
        SET_ACTIVE_STUDENT_FEE_TYPE(state, active_student_fee_types) {
            state.active_student_fee_types=active_student_fee_types;
        },
    },
    actions: {
        async GET_ALL_STUDENT_FEE_TYPE(context){
            await apiCall.get('/student/fee/types')
                    .then(res => {
                        context.commit('SET_ALL_STUDENT_FEE_TYPE',res.data );
                    })
        },
        async GET_ACTIVE_STUDENT_FEE_TYPE(context){
            await apiCall.get('/active/student/fee/types')
                .then(res => {
                    context.commit('SET_ACTIVE_STUDENT_FEE_TYPE',res.data );
                })
        }
    },
}
