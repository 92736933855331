export default [
    
    {
        path: '/expense/type',
        name: 'expense-type',
        component: () => import('@/views/expense/ExpenseType'),
        meta: {
            action:'read',
            resource:'Expense Type',
            pageTitle: 'Expense Type',
            breadcrumb: [
                {
                    text: 'Expense types',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sub/expense',
        name: 'sub-expense',
        component: () => import('@/views/expense/SubExpenseType'),
        meta: {
            action:'read',
            resource:'Sub Expense',
            pageTitle: 'Sub Expense Type',
            breadcrumb: [
                {
                    text: 'Sub Expense Type',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/expense',
        name: 'expense',
        component: () => import('@/views/expense/Expense'),
        meta: {
            action:'read',
            resource:'Expense',
            pageTitle: 'Expense',
            breadcrumb: [
                {
                    text: 'Expense',
                    active: true,
                },
            ],
        },
    },
    

]
