export default [
    {
        path: '/admission/instruction',
        name: 'admission.instruction',
        component: () => import('@/views/admission/AdmissionInstruction.vue'),
        meta: {
            action: 'instruction',
            resource: 'Admission',
            pageTitle: 'Admission Instruction',
            breadcrumb: [
                {
                    text: 'Admission Instruction',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admission/schedule',
        name: 'admission.schedule',
        component: () => import('@/views/admission/AdmissionSchedule.vue'),
        meta: {
            action: 'schedule',
            resource: 'Admission',
            pageTitle: 'Admission Schedule',
            breadcrumb: [
                {
                    text: 'Admission Schedule',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admission/date/and/time',
        name: 'admission.dateTime',
        component: () => import('@/views/admission/AdmissionDateAndTime.vue'),
        meta: {
            action: 'date & time',
            resource: 'Admission',
            pageTitle: 'Admission Date & Time',
            breadcrumb: [
                {
                    text: 'Admission  Date & Time',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admission/fees',
        name: 'admission.fees',
        component: () => import('@/views/admission/AdmissionFees.vue'),
        meta: {
            action: 'fees',
            resource: 'Admission',
            pageTitle: 'Admission Fees',
            breadcrumb: [
                {
                    text: 'Admission Fees',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admission/payments',
        name: 'admission.payment',
        component: () => import('@/views/admission/AdmissionPayment.vue'),
        meta: {
            action: 'payment',
            resource: 'Admission',
            pageTitle: 'Admission payments',
            breadcrumb: [
                {
                    text: 'Admission payments',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/admission/student/list',
        name: 'admission.student.list',
        component: () => import('@/views/admission/StudentList.vue'),
        meta: {
            action: 'student list',
            resource: 'Admission',
            pageTitle: 'Student List',
            breadcrumb: [
                {
                    text: 'Student List',
                    active: true,
                },
            ],
        },
    },

]
