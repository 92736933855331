import apiCall from "@/libs/axios";

export default {
    state: {
        sliders:[],
    },
    getters: {
        sliders: state => {
            return state.sliders;
        },
    },
    mutations: {
        SET_ALL_SLIDER(state, sliders) {
            state.sliders=sliders;
        },
    },
    actions: {
        async GET_ALL_SLIDER(context){
            await apiCall.get('/get/slider/information')
                .then(response => {
                    context.commit('SET_ALL_SLIDER',response.data );
                })
        }
    },
}
