import apiCall from "@/libs/axios";

export default {
    state: {
        subscribers:[],
    },
    getters: {
        subscribers: state => {
            return state.subscribers;
        },
    },
    mutations: {
        SET_ALL_SUBSCRIBER(state, subscribers) {
            state.subscribers=subscribers;
        },
    },
    actions: {
        async GET_ALL_SUBSCRIBER(context){
            await apiCall.get('/get/all/subscribers')
                .then(response => {
                    context.commit('SET_ALL_SUBSCRIBER',response.data );
                })
        }
    },
}
