import apiCall from "@/libs/axios";

export default {
    state: {
        academic_years:[],
        active_academic_years:[],
    },
    getters: {
        academic_years: state => {
            return state.academic_years;
        },
        active_academic_years: state => {
            return state.active_academic_years;
        },
        academic_yearByID: state=>id=>{
            let s=state.academic_years.find(item=>item.id===parseInt(id));
            return s?s.year:'';
        }
    },
    mutations: {
        SET_ALL_ACADEMIC_YEAR(state, academic_years) {
            state.academic_years=academic_years;
        },
        SET_ACTIVE_ACADEMIC_YEAR(state, active_academic_years) {
            state.active_academic_years=active_academic_years;
        },
    },
    actions: {
        async GET_ALL_ACADEMIC_YEAR(context){
            await apiCall.get('/get/all/academic/year')
                    .then(res => {
                        context.commit('SET_ALL_ACADEMIC_YEAR',res.data );
                    })
        },
        async GET_ACTIVE_ACADEMIC_YEAR(context){
            await apiCall.get('/get/active/academic/year')
                .then(res => {
                    context.commit('SET_ACTIVE_ACADEMIC_YEAR',res.data );
                })
        }
    },
}
