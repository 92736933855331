import apiCall from "@/libs/axios";

export default {
    state: {
        funds:[],
    },
    getters: {
        funds: state => {
            return state.funds;
        },
    },
    mutations: {
        SET_EMPLOYEE_FUND(state, funds) {
            state.funds=funds;
        },
    },
    actions: {
        async GET_EMPLOYEE_FUND(context){
            await apiCall.get('/get/employee/funds')
                .then(res => {
                    context.commit('SET_EMPLOYEE_FUND',res.data );
                })
        },
    }
}
