import apiCall from "@/libs/axios";

export default {
    state: {
        expense_types:[],
        active_expense_types:[],
    },
    getters: {
        expense_types: state => {
            return state.expense_types;
        },
        active_expense_types: state => {

            return state.expense_types.filter(item=>item.is_active==1);
        },
        expenseTypeById:state=>id=>{
            return state.expense_types.find(item => item.id === id);
        }
    },
    mutations: {
        SET_ALL_EXPENSE_TYPE(state, expense_types) {
            state.expense_types=expense_types;
        },
        SET_ACTIVE_EXPENSE_TYPE(state, active_expense_types) {
            state.active_expense_types=active_expense_types;
        },
    },
    actions: {
        async GET_ALL_EXPENSE_TYPE(context){
            await apiCall.get('/get/expense/types')
                    .then(res => {
                        context.commit('SET_ALL_EXPENSE_TYPE',res.data );
                    })
        },
        async GET_ACTIVE_EXPENSE_TYPE(context){
            await apiCall.get('/active/expense/types')
                .then(res => {
                    context.commit('SET_ACTIVE_EXPENSE_TYPE',res.data );
                })
        }
    },
}
