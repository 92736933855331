/*import store from '@/store'*/
//import apiCall from "@/libs/axios";
/*const sub_menus = store.getters.public_sub_menus_name_slug;*/
const pub_route=[
    {
        path: '/public/subscriber',
        name: 'public.subscriber',
        component: () => import('@/views/publicSite/Subscriber'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Subscriber',
            breadcrumb: [
                {
                    text: 'Subscribers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/contact/us',
        name: 'public.contact.us',
        component: () => import('@/views/publicSite/ContactUs'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Contact Us',
            breadcrumb: [
                {
                    text: 'Contact Us',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/contact/information',
        name: 'public.contact.information',
        component: () => import('@/views/publicSite/ContactInformation'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Contact Information',
            breadcrumb: [
                {
                    text: 'Contact Information',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/marquee',
        name: 'public.marquee',
        component: () => import('@/views/publicSite/Marquee'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Marquee',
            breadcrumb: [
                {
                    text: 'Marquee',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/slider',
        name: 'public.slider',
        component: () => import('@/views/publicSite/Slider'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Sliders',
            breadcrumb: [
                {
                    text: 'Sliders',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/home/page',
        name: 'public.home.page',
        component: () => import('@/views/publicSite/HomePage'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Home page',
            breadcrumb: [
                {
                    text: 'Public home page information',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/principal/message',
        name: 'public.principal.message',
        component: () => import('@/views/publicSite/PrincipalMessage'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Principal',
            breadcrumb: [
                {
                    text: 'Message',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/facilities',
        name: 'public.facilities',
        component: () => import('@/views/publicSite/Facilities'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Facilities',
            breadcrumb: [
                {
                    text: 'Public Facilities',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/sub/menu',
        name: 'public.sub.menu',
        component: () => import('@/views/publicSite/SubMenu'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Public Sub Menus',
            breadcrumb: [
                {
                    text: 'Public Sub Menus',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/menu',
        name: 'public.menu',
        component: () => import('@/views/publicSite/Menu'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Public Menus',
            breadcrumb: [
                {
                    text: 'Public Menus',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/:type/information/about',
        name: 'public.information.about',
        props:true,
        component: () => import('@/views/publicSite/FooterCommon'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Public Information About',
            breadcrumb: [
                {
                    text: 'Information About',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/:type/information/for',
        name: 'public.information.for',
        props:true,
        component: () => import('@/views/publicSite/FooterCommon'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Public Information For',
            breadcrumb: [
                {
                    text: 'Information For',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/:type/quick/link',
        name: 'public.quick.link',
        props:true,
        component: () => import('@/views/publicSite/FooterCommon'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Public Quick Link',
            breadcrumb: [
                {
                    text: 'Quick Link',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/public/social/link',
        name: 'public.social.link',
        component: () => import('@/views/publicSite/SocialLink'),
        meta: {
            action:'read',
            resource:'Pubic Site',
            pageTitle: 'Social Link',
            breadcrumb: [
                {
                    text: 'Public',
                },
                {
                    text: 'Social Link',
                    active: true,
                },
            ],
        },
    },

];
export default pub_route;
