import apiCall from "@/libs/axios";

export default {
    state: {
        contact_us:[],
    },
    getters: {
        contact_us: state => {
            return state.contact_us;
        },
    },
    mutations: {
        SET_ALL_CONTACT_US(state, contact_us) {
            state.contact_us=contact_us;
        },
    },
    actions: {
        async GET_ALL_CONTACT_US(context){
            await apiCall.get('/get/all/contact/us/information')
                .then(response => {
                    context.commit('SET_ALL_CONTACT_US',response.data );
                })
        }
    },
}
