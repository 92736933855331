export default [
    {
        path: '/sms/single',
        name: 'single-sms',
        component: () => import('@/views/sms/SingleSms.vue'),
        meta: {
            action: 'create',
            resource: 'SMS',
            pageTitle: 'SMS',
            breadcrumb: [
                {
                    text: 'Single SMS',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sms/group',
        name: 'group-sms',
        component: () => import('@/views/sms/GroupSms.vue'),
        meta: {
            action: 'create',
            resource: 'SMS',
            pageTitle: 'SMS',
            breadcrumb: [
                {
                    text: 'Group SMS',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/sms/report',
        name: 'sms-report',
        component: () => import('@/views/sms/SmsReport.vue'),
        meta: {
            action: 'read',
            resource: 'SMS',
            pageTitle: 'SMS',
            breadcrumb: [
                {
                    text: 'SMS Report',
                    active: true,
                },
            ],
        },
    },

]
