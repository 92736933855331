import apiCall from "@/libs/axios";

export default {
    state: {
        users:[],
    },
    getters: {
        users: state => {
            return state.users;
        },
        userListByRole: state=>id=>{
            let userList = state.users.find(item=>item.role_id===parseInt(id));
            return userList?userList: null;
        },
    },
    mutations: {
        SET_ALL_USERS(state, users) {
            state.users = users;
        }
    },
    actions: {
        async GET_ALL_USERS(context){
           await apiCall.get('/get/current/user/list')
                .then(result => {
                    context.commit('SET_ALL_USERS', result.data );
                })
        }
    },
}
