export default [
    
    {
        path: '/student/waiver/type',
        name: 'student-waiver-type',
        component: () => import('@/views/studentWaiver/StudentWaiverType'),
        meta: {
            action:'read',
            resource:'Waiver Type',
            pageTitle: 'Student Waiver',
            breadcrumb: [
                {
                    text: 'Waiver Types',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/waiver',
        name: 'student-waiver',
        component: () => import('@/views/studentWaiver/StudentWaiver'),
        meta: {
            action:'read',
            resource:'Waiver',
            pageTitle: 'Student Waiver',
            breadcrumb: [
                {
                    text: 'Waivers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/waiver/forAll',
        name: 'student-waiver-for-all',
        component: () => import('@/views/studentWaiver/StudentWaiverForAll'),
        meta: {
            action:'read',
            resource:'Waiver For All',
            pageTitle: 'Student Waiver',
            breadcrumb: [
                {
                    text: 'Waivers for all',
                    active: true,
                },
            ],
        },
    },

]
