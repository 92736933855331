import apiCall from "@/libs/axios";

export default {
    state: {
        genders:[],
    },
    getters: {
        genders: state => {
            return state.genders;
        },
        genderById: state=>id=>{
            let s=state.genders.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_GENDER(state, genders) {
            state.genders=genders;
        },
    },
    actions: {
        async GET_ALL_GENDER(context){
            await apiCall.get('/get/all/genders')
                    .then(res => {
                        context.commit('SET_ALL_GENDER',res.data );
                    })
        },
    },
}
