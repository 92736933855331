import apiCall from "@/libs/axios";

export default {
    state: {
        provident_funds:[],
    },
    getters: {
        provident_funds: state => {
            return state.provident_funds;
        },
    },
    mutations: {
        SET_ALL_PROVIDENT_FUND(state, provident_funds) {
            state.provident_funds=provident_funds;
        },
    },
    actions: {
        async GET_ALL_PROVIDENT_FUND(context){
           await apiCall.get('/get/employee/provident/funds')
                .then(res => {
                    context.commit('SET_ALL_PROVIDENT_FUND',res.data );
                })
        }
    },
}
