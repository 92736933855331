import apiCall from "@/libs/axios";

export default {
    state: {
        menu_icons:[],
    },
    getters: {
        menu_icons: state => {
            return state.menu_icons;
        },
        menu_icon_by_id:state=>id=>{
            return state.menu_icons.find(item=>item.id==id);
        }
    },
    mutations: {
        SET_ALL_MENU_ICON(state, menu_icons) {
            state.menu_icons=menu_icons;
        },
    },
    actions: {
        async GET_ALL_MENU_MENU_ICON(context){
            await apiCall.get('/get/menu/icon')
                .then(response => {
                    context.commit('SET_ALL_MENU_ICON',response.data );
                })
        },
    },
}
