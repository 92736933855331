import Vue from 'vue'
import VueRouter from 'vue-router'
import {getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn} from "@/auth/utils";
import {canNavigate} from "@/libs/acl/routeProtection";
import setting from "@/router/setting";
import page from "@/router/page";
import subject from "@/router/subject";
import routine from "@/router/routine";
import task from "@/router/task";
import fee from "@/router/fee";
import reports from "@/router/reports";
import expense from "@/router/expense";
import publicSite from "@/router/publicSite";
import apiCall from "@/libs/axios";
import MainPage from "@/views/publicSite/page/MainPage";
import store from "@/store";
import student from "@/router/student";
import payment from "@/router/payment";
import bankAndAccount from "@/router/bankAndAccount";
import studentWaiver from "@/router/studentWaiver";
import employee from "@/router/employee"
import employeeTask from "@/router/employeePanel/employeeTask";
import personal from "@/router/employeePanel/personal";
import exam from "@/router/exam"
import result from "@/router/result";
import studentPanel from "@/router/studentPanel"
import parentPanel from "@/router/parentPanel"
import roleAndPermission from "@/router/roleAndPermission";
import attendance from "@/router/attendance";
import sms from "@/router/sms";
import admission from "@/router/admission";
import hicv from "@/router/hicv";
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },

  routes: [
      ...hicv,
      ...admission,
      ...roleAndPermission,
      ...page,
      ...subject,
      ...routine,
      ...task,
      ...fee,
      ...reports,
      ...studentWaiver,
      ...expense,
      ...setting,
      ...publicSite,
      ...student,
      ...payment,
      ...bankAndAccount,
      ...employee,
      ...exam,
      ...result,
      ...attendance,

      ...employeeTask,
      ...personal,

      ...studentPanel,
      ...parentPanel,
      ...sms,
  ],
})
router.beforeEach((to, _, next) => {
  //store.commit('LOADER_STATUS_CHANGE',true);
  const isLoggedIn = isUserLoggedIn();
  if (store.getters.public_menus.length <1) {
    //store.dispatch('GET_ALL_MENU_WITH_SUBMENU');
    apiCall.get('/only/public/sub/menus')
        .then(response => {
          response.data.map(item=>{
            let l = router.resolve({name: `public.common.page.${item.slug}`});
            if( l.resolved.matched.length < 1){
              router.addRoutes ([{
                path: `/public/:slug`,
                name: `public.common.page.${item.slug}`,
                props:true,
                component: MainPage,
                meta: {
                  action:'read',
                  resource:'Pubic Site',
                  pageTitle: item.name,
                  breadcrumb: [
                    {
                      text: item.name,
                      active: true,
                    },
                  ],
                },
              }])
            }
          })
          //pub_route.push(...cns);
        })
  }

  //console.log(isLoggedIn);
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn &&  to.path !=='/login') return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  //loader 2
  //store.commit('LOADER_STATUS_CHANGE',false);
})

export default router
