import apiCall from "@/libs/axios";

export default {
    state: {
        assigned_student_fees:[],
        uploaded_canteen_bills:[],
    },
    getters: {
        assigned_student_fees: state => {
            return state.assigned_student_fees;
        },
        uploaded_canteen_bills: state => {
            return state.uploaded_canteen_bills;
        },
    },
    mutations: {
        SET_ALL_ASSIGNED_STUDENT_FEE(state, assigned_student_fees) {
            state.assigned_student_fees = assigned_student_fees;
        },
        SET_ALL_UPLOADED_CANTEEN_BILLS(state, uploaded_canteen_bills) {
            state.uploaded_canteen_bills = uploaded_canteen_bills;
        },

        SPLICE_ASSIGNED_FEE(state, fee_id){
            let index = state.assigned_student_fees.findIndex(item=>item.id==fee_id);
            if (index >=0) state.assigned_student_fees.splice(index,1);
        },
        STATUS_UPDATE_ASSIGNED_FEE(state, fee_id){
            let index = state.assigned_student_fees.findIndex(item=>item.id==fee_id);
            if (index >=0) {
                if(state.assigned_student_fees[index].is_active ==0) state.assigned_student_fees[index].is_active=1;
                else state.assigned_student_fees[index].is_active=0;
            }
        },
    },
    actions: {
        async GET_ALL_ASSIGNED_STUDENT_FEE(context){
            await apiCall.get('/assign/student/fees')
                    .then(res => {
                        context.commit('SET_ALL_ASSIGNED_STUDENT_FEE',res.data );
                    })
        },
        async GET_ALL_UPLOADED_CANTEEN_BILLS(context){
            await apiCall.get('/get/uploaded/canteen/bills')
                    .then(res => {
                        context.commit('SET_ALL_UPLOADED_CANTEEN_BILLS', res.data );
                    })
        },
        REMOVE_ASSIGNED_FEE(context,fee_id){
            context.commit('SPLICE_ASSIGNED_FEE',fee_id );
        },

        ASSIGNED_FEE_STATUS_CHANGE(context,fee_id){
            context.commit('STATUS_UPDATE_ASSIGNED_FEE',fee_id );
        },
    },
}
