import apiCall from "@/libs/axios";

export default {
    state: {
        group_subjects:[],
    },
    getters: {
        group_subjects: state => {
            return state.group_subjects;
        },
    },
    mutations: {
        SET_ALL_GROUP_SUBJECT(state, group_subjects) {
            state.group_subjects = group_subjects;
        },
    },
    actions: {
        async GET_ALL_GROUP_SUBJECT(context){
            await apiCall.get('/get/all/student/assigned/group/subject')
                .then(res => {
                    context.commit('SET_ALL_GROUP_SUBJECT',res.data );
                })
        },
    },
}
