import apiCall from "@/libs/axios";

export default {
    state: {
        employee_bonuses:[],
    },
    getters: {
        employee_bonuses: state => {
            return state.employee_bonuses;
        },
        employeeBonusByID: state=>id=>{
            let s=state.employee_bonuses.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_EMPLOYEE_BONUS(state, employee_bonuses) {
            state.employee_bonuses=employee_bonuses;
        },
        ADD_NEW_EMPLOYEE_BONUS(state, department) {
            state.employee_bonuses.push(department);
        },
        STATUS_CHANGE_EMPLOYEE_BONUS(state, index) {
            if(state.employee_bonuses[index].is_active) state.employee_bonuses[index].is_active=0;
            else state.employee_bonuses[index].is_active=1;
        },
        REMOVE_EMPLOYEE_BONUS(state, index) {
            state.employee_bonuses.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE_BONUS(context){
           await apiCall.get('/get/all/employee/bonus')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_BONUS',res.data );
                })
        }
    },
}
