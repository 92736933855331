import apiCall from "@/libs/axios";

export default {
    state: {
        subject_teachers:[],
    },
    getters: {
        subject_teachers: state => {
            return state.subject_teachers;
        },
    },
    mutations: {
        SET_ALL_SUBJECT_TEACHER(state, subject_teachers) {
            state.subject_teachers=subject_teachers;
        },
        ADD_SUBJECT_TEACHER(state, teacher) {
            state.subject_teachers.push(teacher);
        },
        REMOVE_SUBJECT_TEACHER(state, index) {
            state.subject_teachers.splice(index,1);
        },
        STATUS_CHANGE_SUBJECT_TEACHER(state, index) {
            if(state.subject_teachers[index].is_active) state.subject_teachers[index].is_active=0;
            else state.subject_teachers[index].is_active=1;
        },
    },
    actions: {
        async GET_ALL_SUBJECT_TEACHER(context){
            await apiCall.get('/subject/teachers')
                .then(res => {
                    context.commit('SET_ALL_SUBJECT_TEACHER',res.data );
                })
        },
    }
}
