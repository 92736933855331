<template>
  <b-card-code>
    <div class="custom-search d-flex">
      <b-button variant="outline-primary" @click="showModal">
        {{ $t('Add New') }}
      </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('Search') }}</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="galaries"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'description'">
          <span v-if="props.row.description">
            <template v-for="(vv,key) in jsonDecode(props.row.description)">
             <img :src="imgPath(vv)" alt="" :key="key" width="50px" height="50px"/>
            </template>
          </span>

        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'is_active'">
          <template>
            <b-form-checkbox :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id,props.row.originalIndex)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
          </template>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="outline-primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button variant="outline-danger" @click="remove(props.row.id,props.row.originalIndex)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
             hide-footer
             :title="model_mode==='add'?$t('Add New'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="add_new_images" enctype="multipart/form-data">
          <b-card-text v-if="photos.length>0">
            <span>{{ $t('Previous Image') }} </span>
          </b-card-text>
          <b-row>
            <b-col md="3" v-for="(vv,key) in photos" :key="key">
              <b-button @click="removeImage(key)" style="float: right;" variant="danger">X</b-button>
              <b-card
                  title=""
                  :img-src="imgPath(vv)"
                  img-alt=""
                  img-top
                  tag="article"
                  style="max-width: 20rem;"
                  class="mb-2"
              >
              </b-card>
            </b-col>
            <b-col md="12"></b-col>
            <b-col md="6">
              <b-card-text>
                <span>{{ $t('Title/Category') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Title')"
                    rules="required"
                >
                  <b-form-input
                      v-model="title"
                      name="title"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('Write title here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-card-text>
                <span>{{ $t('Images') }} </span>
              </b-card-text>
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Images')"
                    :rules="model_mode==='add'?required:''"
                >
                  <b-form-file name="images[]" ref="img" v-model="img" :placeholder="$t('select multiple image')" multiple accept="image/jpeg,image/png,image/jpg"></b-form-file>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,BCardText,BFormFile,
  BModal, BRow, BCol,BCard,BFormCheckbox,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
//import {mapGetters} from "vuex";
//import VueUploadMultipleImage from 'vue-upload-multiple-image'
export default {
  name:'ImageGallery',
  components: {
    BCardCode,
    VueGoodTable,BCardText,BCard,
    BPagination,BFormFile,
    BFormGroup,
    BFormInput,BFormCheckbox,
    BFormSelect,
    BButton,
    BModal,
    BRow, BCol,//VueUploadMultipleImage,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props:{
    sub_menu:{
      type:Object,
      required:true,
    }
  },
  data() {
    return {
      title: '',
      images: [],
      photos: [],
      img:null,
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Title/Category',
          field: 'title',
        },
        {
          label: 'Images',
          field: 'description',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
    }
  },
  methods:{
    jsonDecode(data){
      try{
        return JSON.parse(data);
      }catch (e){
        return [];
      }
    },
    imgPath(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
    removeImage(index){
      this.photos.splice(index,1);
    },
    selectedRow(row){
      this.selected_row=row;
      this.title=row.title;
      this.images=[];
      this.photos=this.jsonDecode(row.description);
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.add_new_images);
          data.append('public_sub_menu_id',this.sub_menu.id);
          data.append('page_design_type_id',this.sub_menu.page_design_type_id);
          if (this.model_mode ==='add'){
            apiCall.post('/menu/multiple/images/store',data).then((response)=>{
              this.hideModal();
              this.$refs.add_new_images.reset();

              this.$refs.simpleRules.reset();
              this.$store.commit('STORE_MULTIPLE_PUBLIC_MENU_DATA', [this.sub_menu.id, response.data.data]);
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            if (this.$refs.img.files.length<1 && this.photos.length <1){
              this.$toaster.error('Please select any image');
              return;
            }
            data.append('previous_images',JSON.stringify(this.photos));
            apiCall.post(`/menu/multiple/images/update${this.selected_row.id}`,data).then((response)=>{
              this.hideModal();
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_SUB_MENU_WITH_DATA');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    statusChange(id,index){
      apiCall.put(`/menu/multiple/data/common/status/change${id}`).then((response)=>{
        this.$store.commit('STATUS_CHANGE_MULTIPLE_PUBLIC_SUB_MENU_DATA',[this.sub_menu.id,index]);
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_SUB_MENU_WITH_DATA');
      });
    },
    remove(id,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/menu/multiple/image/remove${id}`).then((response)=>{
            this.$store.commit('REMOVE_MULTIPLE_PUBLIC_MENU_DATA',[this.sub_menu.id,index]);
            this.$toaster.success(response.data.message);
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.title='';
      this.images=[];
      this.photos=[];
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    /*uploadImageSuccess(formData, index, fileList) {
      this.images.push(fileList[index]);
      console.log('data', formData, index, fileList)
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove (index, done, fileList) {
      console.log('index', index, fileList)
      var r = confirm("remove image")
      if (r == true) {
        done()
      } else {
        //noting
      }
    },
    editImage (formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
    }*/
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    galaries(){
      return this.sub_menu.public_data_multiple??[];
    },
    //...mapGetters(['versions']),
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>