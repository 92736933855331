import apiCall from "@/libs/axios";

export default {
    state: {
        admission_dates: [],
    },
    getters: {
        admission_dates: state => {
            return state.admission_dates;
        },
    },
    mutations: {
        SET_ALL_ADMISSION_DATE(state, admission_dates) {
            state.admission_dates=admission_dates;
        },
    },
    actions: {
        async GET_ALL_ADMISSION_DATE(context){
            await apiCall.get('/admission/dates')
                .then(res => {
                    context.commit('SET_ALL_ADMISSION_DATE',res.data );
                })
        },
    },
}
