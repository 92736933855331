import apiCall from "@/libs/axios";

export default {
    state: {
        admission_instruction: {},
    },
    getters: {
        admission_instruction: state => {
            return state.admission_instruction;
        },
    },
    mutations: {
        SET_ALL_ADMISSION_INSTRUCTION(state, admission_instruction) {
            state.admission_instruction=admission_instruction;
        },
    },
    actions: {
        async GET_ALL_ADMISSION_INSTRUCTION(context){
            await apiCall.get('/admission/instruction/information')
                .then(res => {
                    context.commit('SET_ALL_ADMISSION_INSTRUCTION',res.data );
                })
        },
    },
}
