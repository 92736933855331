import apiCall from "@/libs/axios";

export default {
    state: {
        scales:[],
    },
    getters: {
        scales: state => {
            return state.scales;
        },
        scaleByID: state=>id=>{
            let s=state.scales.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_SCALE(state, scales) {
            state.scales=scales;
        },
        ADD_NEW_SCALE(state, scale) {
            state.scales.push(scale);
        },
        STATUS_CHANGE_SCALE(state, index) {
            if(state.scales[index].is_active) state.scales[index].is_active=0;
            else state.scales[index].is_active=1;
        },
        REMOVE_SCALE(state, index) {
            state.scales.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_SCALE(context){
           await apiCall.get('/get/all/scale')
                .then(res => {
                    context.commit('SET_ALL_SCALE',res.data );
                })
        }
    },
}
