export default [
    {
        path: '/grade/type',
        name: 'grade-type',
        component: () => import('@/views/examManage/GradeType'),
        meta: {
            action: 'read',
            resource: 'Grade Manage',
            pageTitle: 'Grade Type',
            breadcrumb: [
                {
                    text: 'Grades',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/exam/type',
        name: 'exam-type',
        component: () => import('@/views/examManage/ExamType'),
        meta: {
            action: 'read',
            resource: 'Exam Type',
            pageTitle: 'Exam Type',
            breadcrumb: [
                {
                    text: 'Exam types',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/mark/distribution/update',
        name: 'mark-distribution',
        component: () => import('@/views/examManage/MarkDistributionUpdate'),
        meta: {
            action: 'read',
            resource: 'Mark Distribution',
            pageTitle: 'Mark distribution',
            breadcrumb: [
                {
                    text: 'Exam',
                },
                {
                    text: 'Mark distribution',
                },
                {
                    text: 'view/update',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/mark/distribution',
        name: 'mark-distribution',
        component: () => import('@/views/examManage/MarkDistribution'),
        meta: {
            action: 'create',
            resource: 'Mark Distribution',
            pageTitle: 'Mark distribution',
            breadcrumb: [
                {
                    text: 'Exam',
                },
                {
                    text: 'Mark distribution',
                },
                {
                    text: 'Add New',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/mark/upload/allow',
        name: 'allow-mark-upload',
        component: () => import('@/views/examManage/MarkUploadAllow'),
        meta: {
            action: 'create',
            resource: 'Allow Mark Upload',
            pageTitle: 'Allow Mark Upload',
            breadcrumb: [
                {
                    text: 'Mark Uploading',
                },
                {
                    text: 'Allow Mark Upload',
                },
                {
                    text: 'Extend Date',
                    active: true,
                },
            ],
        },
    },

]
