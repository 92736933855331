import apiCall from "@/libs/axios";

export default {
    state: {
        exam_routines:[],
        active_exam_routine:[],
    },
    getters: {
        exam_routines: state => {
            return state.exam_routines;
        },
        active_exam_routine: state => {
            return state.active_exam_routine;
        },
    },
    mutations: {
        SET_ALL_EXAM_ROUTINE(state, exam_routines) {
            state.exam_routines=exam_routines;
        },
        SET_ACTIVE_EXAM_ROUTINE(state, active_exam_routine) {
            state.active_exam_routine=active_exam_routine;
        },
    },
    actions: {
        async GET_ALL_EXAM_ROUTINE(context){
            await apiCall.get('/exam/routines')
                .then(res => {
                    context.commit('SET_ALL_EXAM_ROUTINE',res.data );
                })
        },
        async GET_ACTIVE_EXAM_ROUTINE(context){
            await apiCall.get('/active/exam/routines')
                .then(res => {
                    context.commit('SET_ACTIVE_EXAM_ROUTINE',res.data );
                })
        }
    },
}
