import apiCall from "@/libs/axios";

export default {
    state: {
        class_periods:[],
        active_class_periods:[],
    },
    getters: {
        class_periods: state => {
            return state.class_periods;
        },
        active_class_periods: state => {
            return state.active_class_periods;
        },
    },
    mutations: {
        SET_ALL_CLASS_PERIOD(state, class_periods) {
            state.class_periods=class_periods;
        },
        SET_ACTIVE_CLASS_PERIOD(state, active_class_periods) {
            state.active_class_periods=active_class_periods;
        },
    },
    actions: {
        async GET_ALL_CLASS_PERIOD(context){
            await apiCall.get('/get/all/class/period')
                .then(res => {
                    context.commit('SET_ALL_CLASS_PERIOD',res.data );
                })
        },
        async GET_ACTIVE_CLASS_PERIOD(context){
            await apiCall.get('/get/active/class/period')
                .then(res => {
                    context.commit('SET_ACTIVE_CLASS_PERIOD',res.data );
                })
        }
    },
}
