import apiCall from "@/libs/axios";

export default {
    state: {
        designation_scales:[],
    },
    getters: {
        designation_scales: state => {
            return state.designation_scales;
        },
        designationScaleByID: state=>id=>{
            let s=state.designation_scales.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        designationScaleAmountByID: state=>id=>{
            let s=state.designation_scales.find(item=>item.id===parseInt(id));
            return s?s.basic_salary:'';
        }
    },
    mutations: {
        SET_ALL_DESIGNATION_SCALE(state, designation_scales) {
            state.designation_scales=designation_scales;
        },
        ADD_NEW_DESIGNATION_SCALE(state, scale) {
            state.designation_scales.push(scale);
        },
        STATUS_CHANGE_DESIGNATION_SCALE(state, index) {
            if(state.designation_scales[index].is_active) state.designation_scales[index].is_active=0;
            else state.designation_scales[index].is_active=1;
        },
        REMOVE_DESIGNATION_SCALE(state, index) {
            state.designation_scales.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_DESIGNATION_SCALE(context){
           await apiCall.get('/get/all/designation/scale')
                .then(res => {
                    context.commit('SET_ALL_DESIGNATION_SCALE',res.data );
                })
        }
    },
}
