import apiCall from "@/libs/axios";

export default {
    state: {
        bank_accounts:[],
    },
    getters: {
        bank_accounts: state => {
            return state.bank_accounts;
        },
        active_bank_accounts:state=>{
            return state.bank_accounts.filter(item=>item.is_active===1);
        },
        bank_accountById:state=>id=>{
            return state.bank_accounts.find(item=>parseInt(item.bank_id)===parseInt(id));
        },
        active_bank_accountById:state=>id=>{
            return state.bank_accounts.filter(item=>item.is_active===1 && parseInt(item.bank_id)===parseInt(id));
        },
    },
    mutations: {
        SET_ALL_BANK_ACCOUNT(state, bank_accounts) {
            state.bank_accounts=bank_accounts;
        },
        ADD_NEW_BANK_ACCOUNT(state,data){
            state.bank_accounts.push(data);
        },
        UPDATE_BANK_ACCOUNT(state,[data,index]){
            this.$set(state.bank_accounts,index,data);
            //state.bank_accounts[index]=data;
        },
        REMOVE_BANK_ACCOUNT(state,index){
            state.bank_accounts.splice(index,1);
        },
        BANK_ACCOUNT_STATUS_CHANGE(state,index){
            state.bank_accounts[index].is_active= !state.bank_accounts[index].is_active;
        },
    },
    actions: {
        async GET_ALL_BANK_ACCOUNT(context){
            await apiCall.get('/get/bank/accounts')
                .then(res => {
                    context.commit('SET_ALL_BANK_ACCOUNT',res.data );
                })
        },
        async GET_ACTIVE_BANK_ACCOUNT(context){
            await apiCall.get('/active/bank/accounts')
                .then(res => {
                    context.commit('SET_ACTIVE_BANK_ACCOUNT',res.data );
                })
        }
    },
}
