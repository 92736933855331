import apiCall from "@/libs/axios";

export default {
    state: {
        religions:[],
        active_religions:[],
    },
    getters: {
        religions: state => {
            return state.religions;
        },
        active_religions: state => {
            return state.active_religions;
        },
        religionById: state=>id=>{
            let s=state.religions.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_RELIGION(state, religions) {
            state.religions=religions;
        },
        SET_ALL_ACTIVE_RELIGION(state, active_religions) {
            state.active_religions=active_religions;
        },
    },
    actions: {
        async GET_ALL_RELIGION(context){
            await apiCall.get('/get/all/religion')
                    .then(res => {
                        context.commit('SET_ALL_RELIGION',res.data );
                    })
        },
        async GET_ALL_ACTIVE_RELIGION(context){
            await apiCall.get('/get/active/religion')
                .then(res => {
                    context.commit('SET_ALL_ACTIVE_RELIGION',res.data );
                })
        }
    },
}
