import apiCall from "@/libs/axios";

export default {
    state: {
        institute: {},
    },
    getters: {
        institute: state => {
            return state.institute;
        },
    },
    mutations: {
        SET_ALL_INSTITUTE(state, institute) {
            state.institute=institute;
        },
    },
    actions: {
        async GET_ALL_INSTITUTE(context){
            //context.commit('LOADER_STATUS_CHANGE',true);
            await apiCall.get('/get/institute/information')
                .then(res => {
                    //context.commit('LOADER_STATUS_CHANGE',false);
                    context.commit('SET_ALL_INSTITUTE',res.data );
                }).catch(()=>{
                    //context.commit('LOADER_STATUS_CHANGE',false);
                })
        },
    },
}
