import apiCall from "@/libs/axios";

export default {
    state: {
        classes:[],
    },
    getters: {
        classes: state => {
            return state.classes;
        },
        classByID: state=>id=>{
            let s=state.classes.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_CLASSES(state, classes) {
            state.classes=classes;
        },
    },
    actions: {
        async GET_ALL_CLASSES(context){
            await apiCall.get('/get/all/classes')
                    .then(res => {
                        context.commit('SET_ALL_CLASSES',res.data );
                    })
        }
    },
}
