import apiCall from "@/libs/axios";

export default {
    state: {
        sub_expense_types:[],
        active_sub_expense_types:[],
    },
    getters: {
        sub_expense_types: state => {
            return state.sub_expense_types;
        },
        active_sub_expense_types: state => {
            return state.active_sub_expense_types;
        },
        subExpenseTypeById: state=> id=> {
            return state.sub_expense_types.find(item=>item.id===id);
        },
        /*active bank account by bank id*/
        activeSubExpenseTypeById: state=> id=> {
            return state.active_sub_expense_types.filter(item=>item.id===id);
        },
    },
    mutations: {
        SET_ALL_SUB_EXPENSE_TYPE(state, sub_expense_types) {
            state.sub_expense_types=sub_expense_types;
        },
        SET_ACTIVE_SUB_EXPENSE_TYPE(state, active_sub_expense_types) {
            state.active_sub_expense_types=active_sub_expense_types;
        },
    },
    actions: {
        async GET_ALL_SUB_EXPENSE_TYPE(context){
            await apiCall.get('/get/sub/expense/types')
                    .then(res => {
                        context.commit('SET_ALL_SUB_EXPENSE_TYPE',res.data );
                    })
        },
        async GET_ACTIVE_SUB_EXPENSE_TYPE(context){
            await apiCall.get('/active/sub/expense/types')
                .then(res => {
                    context.commit('SET_ACTIVE_SUB_EXPENSE_TYPE',res.data );
                })
        }
    },
}
