import apiCall from "@/libs/axios";

export default {
    state: {
        student_waiver_for_all:[],
        active_student_waiver_for_all:[],
    },
    getters: {
        student_waiver_for_all: state => {
            return state.student_waiver_for_all;
        },
        active_student_waiver_for_all: state => {
            return state.active_student_waiver_for_all;
        },
        studentWaiverForAllById: state=>id=>{
            return state.student_waiver_for_all.find(item=>item.id==id);
        }
    },
    mutations: {
        SET_ALL_STUDENT_WAIVER_FOR_ALL(state, student_waiver_for_all) {
            state.student_waiver_for_all=student_waiver_for_all;
        },
        SET_ACTIVE_STUDENT_WAIVER_FOR_ALL(state, active_student_waiver_for_all) {
            state.active_student_waiver_for_all=active_student_waiver_for_all;
        },
        ADD_NEW_STUDENT_WAIVER_FOR_ALL(state, data) {
            state.student_waiver_for_all.push(data);
        },
        STATUS_CHANGE_STUDENT_WAIVER_FOR_ALL(state, index) {
            if(state.student_waiver_for_all[index].is_active ==1){
                state.student_waiver_for_all[index].is_active =0
            }else{
                state.student_waiver_for_all[index].is_active =1
            }
        },
        APPROVAL_STATUS_CHANGE_STUDENT_WAIVER_FOR_ALL(state, [index,status]) {
            state.student_waiver_for_all[index].approval_status =status;
        },
        REMOVE_STUDENT_WAIVER_FOR_ALL(state, index) {
            state.student_waiver_for_all.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_STUDENT_WAIVER_FOR_ALL(context){
            await apiCall.get('/get/student/waiver/for/all')
                .then(res => {
                    context.commit('SET_ALL_STUDENT_WAIVER_FOR_ALL',res.data );
                })
        },
        async GET_ACTIVE_STUDENT_WAIVER_FOR_ALL(context){
            await apiCall.get('/get/active/student/waiver/for/all')
                .then(res => {
                    context.commit('SET_ACTIVE_STUDENT_WAIVER_FOR_ALL',res.data );
                })
        }
    },
}
