import apiCall from "@/libs/axios";

export default {
    state: {
        page_design_types:[],
    },
    getters: {
        page_design_types: state => {
            return state.page_design_types;
        },
        page_design_type_by_id: state =>id=> {
            return state.page_design_types.find(item=>item.id==id);
        },
    },
    mutations: {
        SET_ALL_PAGE_DESIGN_TYPE(state, page_design_types) {
            state.page_design_types=page_design_types;
        },
    },
    actions: {
        async GET_ALL_PAGE_DESIGN_TYPE(context){
            await apiCall.get('/get/all/page/design/types')
                .then(response => {
                    context.commit('SET_ALL_PAGE_DESIGN_TYPE',response.data );
                })
        },
    },
}
