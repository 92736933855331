export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/user/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/reset-password-by-user',
        name: 'reset-password-by-user',
        component: () => import('@/views/user/ResetPasswordByUser.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/not-authorized',
        name: 'misc-not-authorized',
        component: () => import('@/views/miscellaneous/NotAuthorized'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '/blank',
        name: 'blank_page',
        component: () => import('@/views/miscellaneous/BlankPage'),
        meta: {
            layout: 'full',
            resource: 'Auth',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },

]
