import apiCall from "@/libs/axios";

export default {
    state: {
        public_menus:[],
        all_public_menu:[],
        loaded:false,
    },
    getters: {
        public_menus: state => {
            return state.public_menus;
        },
        all_public_menu: state => {
            return state.all_public_menu;
        },
        public_sub_menus_name_slug: state =>{
            const s_menu=[];
            state.public_menus.map(item=>{
                s_menu.push(...item.public_sub_menus);
            });
            return state.public_menus;
        },
        loaded:state=>{
            return state.loaded;
        },
        menu_by_id:state=>id=>{
            return state.all_public_menu.find(item => parseInt(item.id) === parseInt(id));
        }
    },
    mutations: {
        SET_ALL_MENU_WITH_SUBMENU(state, public_menus) {
            state.public_menus=public_menus;
        },
        SET_MENUS_NEW_SUBMENU(state, subMenu) {
            let index = state.public_menus.findIndex(item=>item.id==subMenu.public_menu_id);
            if(index>=0) state.public_menus[index].public_sub_menus.push(subMenu);
        },
        UPDATE_MENUS_SUBMENU(state, subMenu) {
            let index = state.public_menus.findIndex(item=>item.id==subMenu.public_menu_id);
            if(index>=0) {
                let index2 =state.public_menus[index].public_sub_menus.findIndex(item=>item.id==subMenu.id);
                state.public_menus[index].public_sub_menus[index2]=subMenu;
            }
        },
        SET_ALL_MENUS(state, all_public_menu) {
            state.all_public_menu=all_public_menu;
        },
        SET_LOADED_MENU(state,status){
            state.loaded=status;
        }
    },
    actions: {
        async GET_ALL_MENU_WITH_SUBMENU(context){
            await apiCall.get('/public/menus')
                .then(response => {
                    context.commit('SET_ALL_MENU_WITH_SUBMENU',response.data );
                })
        },
        async GET_ALL_MENUS(context){
            await apiCall.get('/get/all/public/menu/with/child/count')
                .then(response => {
                    context.commit('SET_ALL_MENUS',response.data );
                })
        }
    },
}
