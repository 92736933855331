import apiCall from "@/libs/axios";

export default {
    state: {
        book_list:[],
        active_book_list:[],
    },
    getters: {
        book_list: state => {
            return state.book_list;
        },
        active_book_list: state => {
            return state.active_book_list;
        },
    },
    mutations: {
        SET_ALL_BOOK_LIST(state, book_list) {
            state.book_list=book_list;
        },
        SET_ACTIVE_BOOK_LIST(state, active_book_list) {
            state.active_book_list=active_book_list;
        },
    },
    actions: {
        async GET_ALL_BOOK_LIST(context){
            await apiCall.get('/book/lists')
                .then(res => {
                    context.commit('SET_ALL_BOOK_LIST',res.data );
                })
        },
        async GET_ACTIVE_BOOK_LIST(context){
            await apiCall.get('/active/book/lists')
                .then(res => {
                    context.commit('SET_ACTIVE_BOOK_LIST',res.data );
                })
        }
    },
}
