export default [

    {
        path: '/subject',
        name: 'subject',
        component: () => import('@/views/subject/Subject.vue'),
        meta: {
            action: 'read',
            resource: 'Subject',
            pageTitle: 'Subject',
            breadcrumb: [
                {
                    text: 'Subject',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/add/subject',
        name: 'add-subject',
        component: () => import('@/views/subject/SubjectAdd.vue'),
        meta: {
            action: 'create',
            resource: 'Subject',
            pageTitle: 'Subject',
            breadcrumb: [
                {
                    text: 'Subject',
                    active: true,
                },
            ],
        },
    }

]
