import apiCall from "@/libs/axios";

export default {
    state: {
        mark_distributions:[],
    },
    getters: {
        mark_distributions: state => {
            return state.mark_distributions;
        },
    },
    mutations: {
        SET_ALL_MARK_DISTRIBUTION(state, mark_distributions) {
            state.mark_distributions=mark_distributions;
        },
    },
    actions: {
        async GET_ALL_MARK_DISTRIBUTION(context){
            await apiCall.get('/get/all/mark/distribution')
                .then(res => {
                    context.commit('SET_ALL_MARK_DISTRIBUTION',res.data );
                })
        },
    },
}
