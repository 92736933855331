import apiCall from "@/libs/axios";

export default {
    state: {
        class_teachers:[],
    },
    getters: {
        class_teachers: state => {
            return state.class_teachers;
        },
    },
    mutations: {
        SET_ALL_CLASS_TEACHER(state, class_teachers) {
            state.class_teachers=class_teachers;
        },
        ADD_CLASS_TEACHER(state, teacher) {
            state.class_teachers.push(teacher);
        },
        REMOVE_CLASS_TEACHER(state, index) {
            state.class_teachers.splice(index,1);
        },
        STATUS_CHANGE_CLASS_TEACHER(state, index) {
            if(state.class_teachers[index].is_active) state.class_teachers[index].is_active=0;
            else state.class_teachers[index].is_active=1;
        },
    },
    actions: {
        async GET_ALL_CLASS_TEACHER(context){
            await apiCall.get('/class/teachers')
                .then(res => {
                    context.commit('SET_ALL_CLASS_TEACHER',res.data );
                })
        },
    }
}
