import apiCall from "@/libs/axios";

export default {
    state: {
        employee_leave_request:[],
        student_leave_request:[],
    },
    getters: {
        employee_leave_request: state => {
            return state.employee_leave_request;
        },
        student_leave_request: state => {
            return state.student_leave_request;
        },
    },
    mutations: {
        SET_ALL_EMPLOYEE_LEAVE_REQUEST(state, employee_leave_request) {
            state.employee_leave_request=employee_leave_request;
        },
        SET_ALL_STUDENT_LEAVE_REQUEST(state, employee_leave_request) {
            state.employee_leave_request=employee_leave_request;
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE_LEAVE_REQUEST(context){
            await apiCall.get('/get/all/employee/leave/request')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_LEAVE_REQUEST',res.data );
                })
        },
        async GET_ALL_STUDENT_LEAVE_REQUEST(context){
            await apiCall.get('/get/all/student/leave/request')
                .then(res => {
                    context.commit('SET_ALL_STUDENT_LEAVE_REQUEST',res.data );
                })
        },
    },
}
