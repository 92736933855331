export default [
    {
        path: '/student/add',
        name: 'add-student',
        component: () => import('@/views/student/StudentAdd'),
        meta: {
            action:'create',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Add Student',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/students',
        name: 'students',
        component: () => import('@/views/student/Student'),
        meta: {
            action:'read',
            resource:'Student',
            pageTitle: 'Students',
            breadcrumb: [
                {
                    text: 'Student List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/group/subject',
        name: 'student.group.subject',
        component: () => import('@/views/student/StudentGroupSubject'),
        meta: {
            action:'read',
            resource:'Group Subject',
            pageTitle: 'Student Group Subject',
            breadcrumb: [
                {
                    text: 'Student',
                },
                {
                    text: 'Group Subject',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/fourth/subject',
        name: 'student.fourth.subject',
        component: () => import('@/views/student/StudentFourthSubject'),
        meta: {
            action:'read',
            resource:'4th subject',
            pageTitle: 'Student Fourth Subject',
            breadcrumb: [
                {
                    text: 'Student',
                },
                {
                    text: 'Fourth Subject',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/assign/student/group/subject',
        name: 'student.group.subject',
        component: () => import('@/views/student/AssignStudentGroupSubject'),
        meta: {
            action:'assign',
            resource:'Group Subject',
            pageTitle: 'Assign Student Group Subject',
            breadcrumb: [
                {
                    text: 'Group Subject',
                },
                {
                    text: 'Assign',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/assign/student/fourth/subject',
        name: 'student.fourth.subject',
        component: () => import('@/views/student/AssignStudentFourthSubject'),
        meta: {
            action:'assign',
            resource:'4th subject',
            pageTitle: 'Assign Student Fourth Subject',
            breadcrumb: [
                {
                    text: 'Fourth Subject',
                },
                {
                    text: 'Assign',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/single/student/promotion',
        name: 'single.student.promotion',
        component: () => import('@/views/student/StudentPromotion'),
        meta: {
            action:'promotion',
            resource:'Student',
            pageTitle: 'Promotion',
            breadcrumb: [
                {
                    text: 'Student',
                },
                {
                    text: 'Promotion',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/student/promotion',
        name: 'class.student.promotion',
        component: () => import('@/views/student/ClassPromotion'),
        meta: {
            action:'promotion',
            resource:'Student',
            pageTitle: 'Promotion',
            breadcrumb: [
                {
                    text: 'Class',
                },
                {
                    text: 'Promotion',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/tc',
        name: 'student.tc',
        component: () => import('@/views/student/StudentTC'),
        meta: {
            action:'tc',
            resource:'Student',
            pageTitle: 'TC',
            breadcrumb: [
                {
                    text: 'Student',
                },
                {
                    text: 'TC',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/parents',
        name: 'student.parent',
        component: () => import('@/views/student/StudentParent'),
        meta: {
            action:'read',
            resource:'Parent',
            pageTitle: 'Parents',
            breadcrumb: [
                {
                    text: 'Student',
                },
                {
                    text: 'Parents',
                    active: true,
                },
            ],
        },
    },
]
