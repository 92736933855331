import apiCall from "@/libs/axios";

export default {
    state: {
        admission_schedule: {},
    },
    getters: {
        admission_schedule: state => {
            return state.admission_schedule;
        },
    },
    mutations: {
        SET_ALL_ADMISSION_SCHEDULE(state, admission_schedule) {
            state.admission_schedule=admission_schedule;
        },
    },
    actions: {
        async GET_ALL_ADMISSION_SCHEDULE(context){
            await apiCall.get('/admission/schedule/information')
                .then(res => {
                    context.commit('SET_ALL_ADMISSION_SCHEDULE',res.data );
                })
        },
    },
}
