import apiCall from "@/libs/axios";

export default {
    state: {
        sections:[],
        map_sections:[],
    },
    getters: {
        sections: state => {
            return state.sections;
        },
        map_sections: state => {
            return state.map_sections;
        },
        sectionById: state=>id=>{
            let s = state.sections.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        getDesiredSections: state=>ids=>{
            var resultArray = new Array();
            var section_array = state.sections; 
           
            for (var i = 0; i < section_array.length; i++) {
              
                if(ids.toString().includes(section_array[i].id)){
                    resultArray.push(section_array[i].name);
                }
            }
            
            return resultArray.toString();
        }

    },
    mutations: {
        SET_ALL_SECTION(state, sections) {
            state.sections=sections;
        },
        SET_ALL_MAP_SECTION(state, map_sections) {
            state.map_sections=map_sections;
        },
    },
    actions: {
        async GET_ALL_SECTION(context){
            await apiCall.get('/get/all/section')
                    .then(res => {
                        context.commit('SET_ALL_SECTION',res.data );
                    })
        },
        async GET_ALL_MAP_SECTION(context,data){
            /*
            * data structure
            * data:{
            * academic_year_id
            * classes_id
            * }
            * */
            await apiCall.post('/get/section/wings',data)
                .then(res => {
                    context.commit('SET_ALL_MAP_SECTION',res.data );
                })
        }
    },
}
