import apiCall from "@/libs/axios";

export default {
    state: {
        admission_fees: [],
    },
    getters: {
        admission_fees: state => {
            return state.admission_fees;
        },
    },
    mutations: {
        SET_ALL_ADMISSION_FEE(state, admission_fees) {
            state.admission_fees=admission_fees;
        },
    },
    actions: {
        async GET_ALL_ADMISSION_FEE(context){
            await apiCall.get('/admission/fees')
                .then(res => {
                    context.commit('SET_ALL_ADMISSION_FEE',res.data );
                })
        },
    },
}
