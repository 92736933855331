import apiCall from "@/libs/axios";

export default {
    state: {
        loans:[],
    },
    getters: {
        loans: state => {
            return state.loans;
        },
    },
    mutations: {
        SET_EMPLOYEE_LOAN(state, loans) {
            state.loans=loans;
        },
    },
    actions: {
        async GET_EMPLOYEE_LOAN(context){
            await apiCall.get('/get/employee/loan')
                .then(res => {
                    context.commit('SET_EMPLOYEE_LOAN',res.data );
                })
        },
    }
}
