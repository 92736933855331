import apiCall from "@/libs/axios";

export default {
    state: {
        facility:{},
    },
    getters: {
        facility: state => {
            return state.facility;
        },
    },
    mutations: {
        SET_ALL_FACILITY(state, facility) {
            state.facility=facility;
        },
    },
    actions: {
        async GET_ALL_FACILITY(context){
            await apiCall.get('/get/all/facility')
                .then(response => {
                    context.commit('SET_ALL_FACILITY',response.data );
                })
        }
    },
}
