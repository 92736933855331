import apiCall from "@/libs/axios";

export default {
    state: {
        home_page:{},
    },
    getters: {
        home_page: state => {
            return state.home_page;
        },
    },
    mutations: {
        SET_ALL_HOME_PAGE(state, home_page) {
            state.home_page=home_page;
        },
    },
    actions: {
        async GET_ALL_HOME_PAGE(context){
            await apiCall.get('/get/home/page/information')
                .then(response => {
                    context.commit('SET_ALL_HOME_PAGE',response.data );
                })
        }
    },
}
