import apiCall from "@/libs/axios";

export default {
    state: {
        notifications:[],
    },
    getters: {
        notifications: state => {
            return state.notifications;
        },
    },
    mutations: {
        SET_ALL_NOTIFICATION(state, notifications) {
            state.notifications=notifications;
        },
    },
    actions: {
        async GET_ALL_NOTIFICATION(context){
            await apiCall.get('/get/my/notification')
                .then(res => {
                    context.commit('SET_ALL_NOTIFICATION',res.data );
                })
        },
    },
}
