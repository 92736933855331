export default [
    {
        path: '/student/personal/subject',
        name: 'student-personal-subject',
        component: () => import('@/views/studentPanel/Subject'),
        meta: {
            action:'subject',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Subjects',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/personal/class/routine',
        name: 'student-personal-class-routine',
        component: () => import('@/views/studentPanel/ClassRoutine'),
        meta: {
            action:'class routine',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Class Routine',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/personal/exam/routine',
        name: 'student-personal-exam-routine',
        component: () => import('@/views/studentPanel/ExamRoutine'),
        meta: {
            action:'exam routine',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Exam Routine',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/personal/home/works',
        name: 'student-personal-home-works',
        component: () => import('@/views/studentPanel/HomeWork'),
        meta: {
            action:'homework',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Home Works',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/personal/course/material',
        name: 'student-personal-course-material',
        component: () => import('@/views/studentPanel/CourseMaterial'),
        meta: {
            action:'course material',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Course Material',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/personal/attendance',
        name: 'student-personal-attendance',
        component: () => import('@/views/studentPanel/Attendance'),
        meta: {
            action:'attendance',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Attendance',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/personal/leave/request',
        name: 'student-personal-leave-request',
        component: () => import('@/views/studentPanel/LeaveRequest'),
        meta: {
            action:'leave request',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Leave Request',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/personal/results',
        name: 'student-personal-results',
        component: () => import('@/views/studentPanel/Result'),
        meta: {
            action:'result',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Result',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/personal/payments',
        name: 'student-personal-payment',
        component: () => import('@/views/studentPanel/Payment'),
        meta: {
            action:'payment',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Payments',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/student/personal/payment/history',
        name: 'student-personal-payment-history',
        component: () => import('@/views/studentPanel/PaymentHistory'),
        meta: {
            action:'payment history',
            resource:'Student',
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'Payment History',
                    active: true,
                },
            ],
        },
    },

]
