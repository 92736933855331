import apiCall from "@/libs/axios";

export default {
    state: {
        salaries:[],
    },
    getters: {
        salaries: state => {
            return state.salaries;
        },
    },
    mutations: {
        SET_ALL_EMPLOYEE_SALARY(state, salaries) {
            state.salaries=salaries;
        },
        ADD_NEW_EMPLOYEE_SALARY(state, salary) {
            state.salaries.push(salary);
        },
        STATUS_CHANGE_EMPLOYEE_SALARY(state, index) {
            if(state.salaries[index].is_active) state.salaries[index].is_active=0;
            else state.salaries[index].is_active=1;
        },
        REMOVE_EMPLOYEE_SALARY(state, index) {
            state.salaries.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE_SALARY(context){
           await apiCall.get('/get/all/employee/salary')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_SALARY',res.data );
                })
        }
    },
}
