export default [
    {
        path: '/result/generate',
        name: 'result-generate',
        component: () => import('@/views/result/ResultGenerate'),
        meta: {
            action:'generate',
            resource:'Result Processing',
            pageTitle: 'Result Generate',
            breadcrumb: [
                {
                    text: 'Result',
                },
                {
                    text: 'Generate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/result/publish',
        name: 'result-publish',
        component: () => import('@/views/result/ResultPublish'),
        meta: {
            action:'publish',
            resource:'Result Processing',
            pageTitle: 'Result Publish',
            breadcrumb: [
                {
                    text: 'Result',
                },
                {
                    text: 'Publish',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/result/by/subject',
        name: 'result-by-subject',
        component: () => import('@/views/result/ResultBySubject'),
        meta: {
            action:'result by subject',
            resource:'Result Processing',
            pageTitle: 'Subject Wise Result',
            breadcrumb: [
                {
                    text: 'Subject',
                },
                {
                    text: 'Results',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/result/by/part',
        name: 'result-by-part',
        component: () => import('@/views/result/ResultByPart'),
        meta: {
            action:'result by subject',
            resource:'Result Processing',
            pageTitle: 'Subject Part Result',
            breadcrumb: [
                {
                    text: 'Results',
                },
                {
                    text: 'Combined Report',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/all/result',
        name: 'all-result',
        component: () => import('@/views/result/Result'),
        meta: {
            action:'all result',
            resource:'Result Processing',
            pageTitle: 'Results',
            breadcrumb: [
                {
                    text: 'Results',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/single/result:id',
        name: 'all-result',
        props:true,
        component: () => import('@/views/result/SingleResult'),
        meta: {
            action:'all result',
            resource:'Result Processing',
            pageTitle: 'Results',
            breadcrumb: [
                {
                    text: 'Single Student',
                    active: true,
                },
                {
                    text: 'Results',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/summary/of/total/result',
        name: 'summary-of-total',
        component: () => import('@/views/result/SummaryOfTotal'),
        meta: {
            action:'result by subject',
            resource:'Result Processing',
            pageTitle: 'Summary Of Total Results',
            breadcrumb: [
                {
                    text: 'Results',
                },
                {
                    text: 'Summary Of Total Results',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/summary/of/grade/point/result',
        name: 'summary-of-grade-result',
        component: () => import('@/views/result/SummaryOfGP'),
        meta: {
            action:'result by subject',
            resource:'Result Processing',
            pageTitle: 'Summary Of Grade Point Results',
            breadcrumb: [
                {
                    text: 'Results',
                },
                {
                    text: 'Summary Of Grade Point Results',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/summary/of/grade/point/total/result',
        name: 'summary-of-grade-total-result',
        component: () => import('@/views/result/SummaryOfGPAandTotal'),
        meta: {
            action:'result by subject',
            resource:'Result Processing',
            pageTitle: 'Summary Of GPA and Total Results',
            breadcrumb: [
                {
                    text: 'Results',
                },
                {
                    text: 'Summary Of Grade Point and Total Results',
                    active: true,
                },
            ],
        },
    },
]
