export default [
    {
        path: '/bank',
        name: 'bank',
        component: () => import('@/views/BankAndAccount/Bank'),
        meta: {
            action:'read',
            resource:'Bank',
            pageTitle: 'Bank',
            breadcrumb: [
                {
                    text: 'bank',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/bank/account',
        name: 'bank-account',
        component: () => import('@/views/BankAndAccount/BankAccount'),
        meta: {
            action:'read',
            resource:'Account',
            pageTitle: 'Bank Account',
            breadcrumb: [
                {
                    text: 'Bank account',
                    active: true,
                },
            ],
        },
    },

]
