import apiCall from "@/libs/axios";

export default {
    state: {
        student_groups:[],
    },
    getters: {
        student_groups: state => {
            return state.student_groups;
        },
        student_groupByID: state=>id=>{
            let s=state.student_groups.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_STUDENT_GROUP(state, shifts) {
            state.student_groups=shifts;
        },
    },
    actions: {
        async GET_ALL_STUDENT_GROUP(context){
            await apiCall.get('/get/all/student/group')
                    .then(res => {
                        context.commit('SET_ALL_STUDENT_GROUP',res.data );
                    })
        }
    },
}
