import apiCall from "@/libs/axios";

export default {
    state: {
        student_fee_heads:[],
        active_student_fee_heads:[],
    },
    getters: {
        student_fee_heads: state => {
            return state.student_fee_heads;
        },
        active_student_fee_heads: state => {
            return state.active_student_fee_heads;
        },
    },
    mutations: {
        SET_ALL_STUDENT_FEE_HEAD(state, student_fee_heads) {
            state.student_fee_heads=student_fee_heads;
        },
        SET_ACTIVE_STUDENT_FEE_HEAD(state, active_student_fee_heads) {
            state.active_student_fee_heads=active_student_fee_heads;
        },
    },
    actions: {
        async GET_ALL_STUDENT_FEE_HEAD(context){
            await apiCall.get('/student/fee/heads')
                    .then(res => {
                        context.commit('SET_ALL_STUDENT_FEE_HEAD',res.data );
                    })
        },
        async GET_ACTIVE_STUDENT_FEE_HEAD(context){
            await apiCall.get('/active/student/fee/heads')
                .then(res => {
                    context.commit('SET_ACTIVE_STUDENT_FEE_HEAD', res.data );
                })
        }
    },
}
