export default {
    state: {
        user:{},
        access_token:'',
        refresh_token:'',
    },
    getters: {
        user: state => {
            return state.user;
        },
        access_token: state => {
            return state.access_token;
        },
        refresh_token: state => {
            return state.refresh_token;
        },
    },
    mutations: {
        SET_AUTH_USER_ACCESS(state, data) {
            state.user=data.user;
            state.access_token=data.access_token;
            state.refresh_token=data.refresh_token;
        },
    },
    actions: {
        AUTH_USER_ACCESS(context,data){
            context.commit('SET_AUTH_USER_ACCESS',data);
        },
    },
}
