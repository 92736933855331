import apiCall from "@/libs/axios";

export default {
    state: {
        student_parents:[],
    },
    getters: {
        student_parents: state => {
            return state.student_parents;
        },
    },
    mutations: {
        SET_ALL_STUDENT_PARENT(state, student_parents) {
            state.student_parents=student_parents;
        },
        STUDENT_PARENT_STATUS_CHANGE(state,id){
            let index = state.student_parents.findIndex(item=>item.id===id);
            if (index>=0) state.student_parents[index].is_active===0?state.student_parents[index].is_active=1:state.student_parents[index].is_active=0;
        }
    },
    actions: {
        async GET_ALL_STUDENT_PARENT(context){
            await apiCall.get('/get/all/student_parents')
                .then(res => {
                    context.commit('SET_ALL_STUDENT_PARENT',res.data );
                })
        },
    },
}
