import apiCall from "@/libs/axios";

export default {
    state: {
        employee_course_materials:[],
    },
    getters: {
        employee_course_materials: state => {
            return state.employee_course_materials;
        },
    },
    mutations: {
        SET_ALL_EMPLOYEE_COURSE_MATERIAL(state, employee_course_materials) {
            state.employee_course_materials=employee_course_materials;
        },
        ADD_EMPLOYEE_COURSE_MATERIAL(state, teacher) {
            state.employee_course_materials.push(teacher);
        },
        REMOVE_EMPLOYEE_COURSE_MATERIAL(state, index) {
            state.employee_course_materials.splice(index,1);
        },
        STATUS_CHANGE_EMPLOYEE_COURSE_MATERIAL(state, index) {
            if(state.employee_course_materials[index].is_active) state.employee_course_materials[index].is_active=0;
            else state.employee_course_materials[index].is_active=1;
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE_COURSE_MATERIAL(context){
            await apiCall.get('/employee/course/materials')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_COURSE_MATERIAL',res.data );
                })
        },
    }
}
