<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <form ref="simple_data_form">
        <b-row>
          <b-col md="6">
            <b-card-text>
              <span>{{ $t('Title')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Title')"
                  rules="required"
              >
                <b-form-input name="title"
                              v-model="simple_data.title"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="$t('Write your title here')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-text>
              <span>{{ $t('Description')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('description')"
                  rules="required"
              >
                <vue-editor name="description" :state="errors.length > 0 ? false:null" rows="5"
                            v-model="simple_data.description"
                            :placeholder="$t('Write description here')"></vue-editor>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BRow, BCol, BButton,BCardText,BFormGroup,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import apiCall from "@/libs/axios";
import { VueEditor } from "vue2-editor";
export default {
  name:'SimpleDesign',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BRow,BFormGroup,
    BCol,
    BButton,
    BCardText,VueEditor,
  },
  props:{
    sub_menu:{
      type:Object,
      required:true,
    }
  },
  data() {
    return {
      required,
      simple_data:{},
    }
  },
  created(){
    this.simple_data = this.sub_menu.public_data_simple??{};
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.simple_data_form);
          data.append('public_sub_menu_id',this.sub_menu.id);
          data.append('page_design_type_id',this.sub_menu.page_design_type_id);
          data.append('description',this.simple_data.description);
          if (this.simple_data.id){
            apiCall.post(`/menu/simple/data/update${this.simple_data.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.$toaster.success(response.data.message);
                //this.$refs.simple_data_form.reset();
                this.$refs.simpleRules.reset();
                this.$store.commit('UPDATE_SIMPLE_PUBLIC_MENU_DATA', [this.sub_menu.id, response.data.data]);
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post('/menu/simple/data/store',data).then((response)=>{
              if (response.data.status==='success'){
                this.$toaster.success(response.data.message);
                //this.$refs.simple_data_form.reset();
                this.$refs.simpleRules.reset();
                this.$store.commit('UPDATE_SIMPLE_PUBLIC_MENU_DATA', [this.sub_menu.id, response.data.data]);
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }

      })
    },
  },
  watch:{
    sub_menu(){
      this.simple_data = this.sub_menu.public_data_simple??{};
    }
  }
}
</script>