import apiCall from "@/libs/axios";

export default {
    state: {
        employees:[],
        teachers:[],
        educational_qualifications:[],
        working_experiences:[],
    },
    getters: {
        employees: state => {
            return state.employees;
        },
        teachers: state => {
            return state.teachers;
        },
        employeeNameById: state=>id=>{
            let s = state.employees.find(item=>item.id===parseInt(id));
            return s?s.userable? s.userable.name:'':'';
        },
        employeeIdById: state=>id=>{
            let s= state.employees.find(item=>item.id===parseInt(id));
            return s?s.employee_id:'';
        },
       
        getEmployeeId: state=>id=>{
            let s=state.employees.find(item=>item.id===parseInt(id));
            return s?s.institute_emp_id:'';
        },
        educational_qualifications: state => {
            return state.educational_qualifications;
        },
        working_experiences: state => {
            return state.working_experiences;
        },
    },
    mutations: {
        SET_ALL_EMPLOYEE(state, employees) {
            state.employees=employees;
        },
        SET_ALL_TEACHER(state, teachers) {
            state.teachers = teachers;
        },
        ADD_NEW_EMPLOYEE(state, employee) {
            state.employees.push(employee);
        },
        STATUS_CHANGE_EMPLOYEE(state, index) {
            if(state.employees[index].is_active) state.employees[index].is_active=0;
            else state.employees[index].is_active=1;
        },
        RESULT_SHOWN_STATUS_CHANGE_EMPLOYEE(state, index) {
            if(state.employees[index].is_result_shown) {
                state.employees[index].is_result_shown = 0;
            }
            else {
                state.employees[index].is_result_shown = 1;
            }
        },
        REMOVE_EMPLOYEE(state, index) {
            state.employees.splice(index,1);
        },
        SET_ALL_EMPLOYEE_QUALIFICATION(state, educational_qualifications) {
            state.educational_qualifications=educational_qualifications;
        },
        SET_ALL_EMPLOYEE_EXPERIENCE(state, working_experiences) {
            state.working_experiences=working_experiences;
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE(context){
           await apiCall.get('/get/all/employee')
            .then(res => {
                context.commit('SET_ALL_EMPLOYEE',res.data );
            })
        },

        async GET_ALL_TEACHER(context){
            await apiCall.get('/get/all/teacher')
             .then(result => {
                 context.commit('SET_ALL_TEACHER', result.data );
             })
         },
        async GET_ALL_EMPLOYEE_EDUCATIONAL_QUALIFICATION(context){
            await apiCall.get('/employee/education/qualifications')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_QUALIFICATION',res.data );
                })
        },
        async GET_ALL_EMPLOYEE_WORKING_EXPERIENCE(context){
            await apiCall.get('/employee/work/experiences')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_EXPERIENCE',res.data );
                })
        }
    },
}
