import apiCall from "@/libs/axios";

export default {
    state: {
        grade_types:[],
    },
    getters: {
        grade_types: state => {
            return state.grade_types;
        },
        gradeTypeByID: state=>id=>{
            let s=state.grade_types.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_GRADE_TYPE(state, grade_types) {
            state.grade_types=grade_types;
        },
    },
    actions: {
        async GET_ALL_GRADE_TYPE(context){
            await apiCall.get('/grade/types')
                .then(res => {
                    context.commit('SET_ALL_GRADE_TYPE',res.data );
                })
        },
    },
}
