import apiCall from "@/libs/axios";

export default {
    state: {
        versions:[],
    },
    getters: {
        versions: state => {
            return state.versions;
        },
        versionByID: state=>id=>{
            let s=state.versions.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_VERSION(state, versions) {
            state.versions=versions;
        },
    },
    actions: {
        async GET_ALL_VERSION(context){
           await apiCall.get('/get/all/version')
                .then(res => {
                    context.commit('SET_ALL_VERSION',res.data );
                })
        }
    },
}
