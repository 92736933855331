import apiCall from "@/libs/axios";

export default {
    state: {
        payment_types:[],
    },
    getters: {
        payment_types: state => {
            return state.payment_types;
        },
        active_payment_types:state=>{
            return state.payment_types.filter(item=>item.is_active===1);
        },
        paymentTypeById:state=>id=>{
            return state.payment_types.find(item=>item.id===id);
        }
    },
    mutations: {
        SET_ALL_PAYMENT_TYPE(state, payment_types) {
            state.payment_types=payment_types;
        },
    },
    actions: {
        async GET_ALL_PAYMENT_TYPE(context){
            await apiCall.get('/get/payment/types')
                .then(res => {
                    context.commit('SET_ALL_PAYMENT_TYPE',res.data );
                })
        },
    },
}
