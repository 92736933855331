import Vue from 'vue'
import useJwt from '@core/auth/jwt/useJwt'
// axios
import axios from 'axios'

const apiCall = axios.create({
  baseURL: process.env.VUE_APP_ENV_API_URL,
    timeout: 100000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer' + useJwt.getToken
    }
})

Vue.prototype.$http = apiCall

export default apiCall
