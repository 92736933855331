<template>
    <div>
        <simple-design-without-image v-if="sub_menu && parseInt(sub_menu.page_design_type_id)==1" :sub_menu="sub_menu"></simple-design-without-image>
        <simple-design-with-image v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)==2" :sub_menu="sub_menu"></simple-design-with-image>
        <image-gallery v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)==3" :sub_menu="sub_menu"></image-gallery>
        <video-gallery v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)==4" :sub_menu="sub_menu"></video-gallery>
        <notice-board-design v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)==5" :sub_menu="sub_menu"></notice-board-design>
        <event v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)==6" :sub_menu="sub_menu"></event>
    </div>
</template>

<script>
import SimpleDesignWithoutImage from "@/views/publicSite/page/SimpleDesignWithoutImage";
import SimpleDesignWithImage from "@/views/publicSite/page/SimpleDesignWithImage";
import ImageGallery from "@/views/publicSite/page/ImageGallery";
import VideoGallery from "@/views/publicSite/page/VideoGallery";
import NoticeBoardDesign from "@/views/publicSite/page/NoticeBoardDesign";
import Event from "@/views/publicSite/page/Event";
export default {
  name:'Main-page',
  props:{
    slug:{
      required:true,
    }
  },
  components:{
    SimpleDesignWithoutImage,SimpleDesignWithImage,
    ImageGallery,VideoGallery,NoticeBoardDesign,Event
  },
  created() {
    this.$store.dispatch('GET_ALL_SUB_MENU_WITH_DATA');
  },
  computed:{
      sub_menu(){
        return this.$store.getters.single_sub_menus(this.slug);
      }
  },
  watch:{
      sub_menu(){
        //404 redirector
      }
  }
}
</script>
<style lang="scss" >

</style>