import apiCall from "@/libs/axios";

export default {
    state: {
        admission_payments: [],
    },
    getters: {
        admission_payments: state => {
            return state.admission_payments;
        },
    },
    mutations: {
        SET_ALL_ADMISSION_PAYMENT(state, admission_payments) {
            state.admission_payments=admission_payments;
        },
    },
    actions: {
        async GET_ALL_ADMISSION_PAYMENT(context){
            await apiCall.get('/admission/payments')
                .then(res => {
                    context.commit('SET_ALL_ADMISSION_PAYMENT',res.data );
                })
        },
    },
}
