import apiCall from "@/libs/axios";

export default {
    state: {
        e_student_attendances:[],
    },
    getters: {
        e_student_attendances: state => {
            return state.e_student_attendances;
        },
    },
    mutations: {
        SET_ALL_E_STUDENT_ATTENDANCE(state, e_student_attendances) {
            state.e_student_attendances=e_student_attendances;
        },
    },
    actions: {
        async GET_ALL_E_STUDENT_ATTENDANCE(context){
            await apiCall.get('/employee/student/attendances')
                .then(res => {
                    context.commit('SET_ALL_E_STUDENT_ATTENDANCE',res.data );
                })
        },
    }
}
