import apiCall from "@/libs/axios";

export default {
    state: {
        student_mark_uploads: [],
        student_mark_upload_drafts: [],
        employee_submitted_mark_list: []
    },
    getters: {
        student_mark_uploads: state => {
            return state.student_mark_uploads;
        },
        student_mark_upload_drafts: state => {
            return state.student_mark_upload_drafts;
        },
        employee_submitted_mark_list: state => {
            return state.employee_submitted_mark_list;
        },
    },
    mutations: {
        SET_ALL_EMPLOYEE_UPLOADED_MARK(state, student_mark_uploads) {
            state.student_mark_uploads = student_mark_uploads;
        },
        SET_ALL_UPLOADED_DRAFT_MARK(state, student_mark_upload_drafts) {
            state.student_mark_upload_drafts = student_mark_upload_drafts;
        },
        SET_EMPLOYEE_SUBMITTED_MARK_LIST(state, employee_submitted_mark_list) {
            state.employee_submitted_mark_list = employee_submitted_mark_list;
        },
    },
    actions: {
        async GET_ALL_EMPLOYEE_UPLOADED_MARK(context) {
            await apiCall.get('/employee/student/uploaded/marks')
                .then(res => {
                    context.commit('SET_ALL_EMPLOYEE_UPLOADED_MARK', res.data);
                })
        },

        async GET_ALL_UPLOADED_DRAFT_MARK(context) {
            await apiCall.get('/employee/uploaded/draft/marks')
                .then(response => {
                    context.commit('SET_ALL_UPLOADED_DRAFT_MARK', response.data);
                })
        },

        async GET_EMPLOYEE_SUBMITTED_MARK_LIST(context) {
            await apiCall.get('/employee/submitted/mark/list')
                .then(response => {
                    context.commit('SET_EMPLOYEE_SUBMITTED_MARK_LIST', response.data);
                })
        },
    }
}
