import apiCall from "@/libs/axios";

export default {
    state: {
        marquees:[],
    },
    getters: {
        marquees: state => {
            return state.marquees;
        },
    },
    mutations: {
        SET_ALL_MARQUEE(state, marquees) {
            state.marquees=marquees;
        },
    },
    actions: {
        async GET_ALL_MARQUEE(context){
            await apiCall.get('/get/all/marquee')
                .then(response => {
                    context.commit('SET_ALL_MARQUEE',response.data );
                })
        }
    },
}
