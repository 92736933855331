import apiCall from "@/libs/axios";

export default {
    state: {
        exam_types:[],
        active_exam_types:[],
    },
    getters: {
        exam_types: state => {
            return state.exam_types;
        },
        examTypeByID: state=>id=>{
            let s=state.exam_types.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        },
        active_exam_types: state => {
            return state.active_exam_types;
        },
    },
    mutations: {
        SET_ALL_EXAM_TYPE(state, exam_types) {
            state.exam_types=exam_types;
        },
        SET_ACTIVE_EXAM_TYPE(state, active_exam_types) {
            state.active_exam_types=active_exam_types;
        },
        ADD_EXAM_TYPE(state, type) {
            state.exam_types.push(type);
        },
        REMOVE_EXAM_TYPE(state, index) {
            state.exam_types.splice(index,1);
        },
        STATUS_CHANGE_EXAM_TYPE(state, index) {
            if(state.exam_types[index].is_active) state.exam_types[index].is_active=0;
            else state.exam_types[index].is_active=1;
        },
    },
    actions: {
        async GET_ALL_EXAM_TYPE(context){
            await apiCall.get('/exam/types')
                .then(res => {
                    context.commit('SET_ALL_EXAM_TYPE',res.data );
                })
        },
        async GET_ACTIVE_EXAM_TYPE(context){
            await apiCall.get('/active/exam/types')
                .then(res => {
                    context.commit('SET_ACTIVE_EXAM_TYPE',res.data );
                })
        }
    },
}
