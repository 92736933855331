export default [

    {
        path: '/task/book/list',
        name: 'book-list',
        component: () => import('@/views/task/BookList'),
        meta: {
            action:'read',
            resource:'Book List',
            pageTitle: 'Book list',
            breadcrumb: [
                {
                    text: 'Book list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/syllabus',
        name: 'syllabus',
        component: () => import('@/views/task/Syllabus'),
        meta: {
            action:'read',
            resource:'Syllabus',
            pageTitle: 'Syllabuses',
            breadcrumb: [
                {
                    text: 'Syllabuses',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/class/teacher',
        name: 'class-teacher',
        component: () => import('@/views/task/ClassTeacher'),
        meta: {
            action:'read',
            resource:'Class Teacher',
            pageTitle: 'Class Teacher',
            breadcrumb: [
                {
                    text: 'Class Teachers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/subject/teacher',
        name: 'subject-teacher',
        component: () => import('@/views/task/SubjectTeacher'),
        meta: {
            action:'read',
            resource:'Subject Teacher',
            pageTitle: 'Subject Teacher',
            breadcrumb: [
                {
                    text: 'Subject Teachers',
                    active: true,
                },
            ],
        },
    },
]
