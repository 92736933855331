import apiCall from "@/libs/axios";

export default {
    state: {
        student_fee_options:[],
        active_student_fee_options:[],
    },
    getters: {
        student_fee_options: state => {
            return state.student_fee_options;
        },
        active_student_fee_options: state => {
            return state.active_student_fee_options;
        },
    },
    mutations: {
        SET_ALL_STUDENT_FEE_OPTION(state, student_fee_options) {
            state.student_fee_options=student_fee_options;
        },
        SET_ACTIVE_STUDENT_FEE_OPTION(state, active_student_fee_options) {
            state.active_student_fee_options=active_student_fee_options;
        },
    },
    actions: {
        async GET_ALL_STUDENT_FEE_OPTION(context){
            await apiCall.get('/student/fee/options')
                    .then(res => {
                        context.commit('SET_ALL_STUDENT_FEE_OPTION',res.data );
                    })
        },
        async GET_ACTIVE_STUDENT_FEE_OPTION(context){
            await apiCall.get('/active/student/fee/options')
                .then(res => {
                    context.commit('SET_ACTIVE_STUDENT_FEE_OPTION',res.data );
                })
        }
    },
}
