import apiCall from "@/libs/axios";

export default {
    state: {
        expenses:[],
    },
    getters: {
        expenses: state => {
            return state.expenses;
        },
    },
    mutations: {
        SET_ALL_EXPENSE(state, expenses) {
            state.expenses=expenses;
        },
        ADD_NEW_EXPENSE(state,expense){
            state.expenses.push(expense);
        },
        REMOVE_EXPENSE(state,index){
            state.expenses.splice(index,1);
        },
        UPDATE_EXPENSE(state,[expense,index]){
            state.expenses[index]=expense;
        },
        APPROVE_EXPENSE(state,index){
            state.expenses[index].status=1;
        },
        CANCEL_EXPENSE(state,index){
            state.expenses[index].status=2;
        }
    },
    actions: {
        async GET_ALL_EXPENSE(context){
            await apiCall.get('/get/expenses')
                    .then(res => {
                        context.commit('SET_ALL_EXPENSE',res.data );
                    })
        },
    },
}
