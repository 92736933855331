import apiCall from "@/libs/axios";

export default {
    state: {
        footers:[],
    },
    getters: {
        footers: state => {
            return state.footers;
        },
        footerByType:state=>type=>{
            return state.footers.filter(item=>item.type===type);
        },
        footerByID:state=>id=>{
            return state.footers.find(item=>item.id===parseInt(id));
        }
    },
    mutations: {
        SET_ALL_FOOTER(state, footers) {
            state.footers=footers;
        },
    },
    actions: {
        async GET_ALL_FOOTER(context){
            await apiCall.get('/get/footer/information')
                .then(response => {
                    context.commit('SET_ALL_FOOTER',response.data );
                })
        }
    },
}
