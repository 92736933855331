import apiCall from "@/libs/axios";

export default {
    state: {
        bonus_types:[],
    },
    getters: {
        bonus_types: state => {
            return state.bonus_types;
        },
        bonusTypeByID: state=>id=>{
            let s=state.bonus_types.find(item=>item.id===parseInt(id));
            return s?s.name:'';
        }
    },
    mutations: {
        SET_ALL_BONUS_TYPE(state, bonus_types) {
            state.bonus_types=bonus_types;
        },
        ADD_NEW_BONUS_TYPE(state, department) {
            state.bonus_types.push(department);
        },
        STATUS_CHANGE_BONUS_TYPE(state, index) {
            if(state.bonus_types[index].is_active) state.bonus_types[index].is_active=0;
            else state.bonus_types[index].is_active=1;
        },
        REMOVE_BONUS_TYPE(state, index) {
            state.bonus_types.splice(index,1);
        },
    },
    actions: {
        async GET_ALL_BONUS_TYPE(context){
           await apiCall.get('/get/all/bonus/type')
                .then(res => {
                    context.commit('SET_ALL_BONUS_TYPE',res.data );
                })
        }
    },
}
